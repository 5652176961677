#lavender-template-cv {
    $prim-color: #4f3888;
    // padding: 30px;
    width: 100%;
    min-width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;

    progress::-moz-progress-bar {
        background: $prim-color;
    }
    progress::-webkit-progress-value {
        background: $prim-color;
    }

    .lavender-left-ribbon {
        height: 1000px;
        width: 50px;
        border-right: 2px solid #fcb302;
        position: absolute;
        z-index: 1;
        left: 0;
        top: 300px;
        @media screen and (max-width: 600px) {
            width: 25px;
            top: 200px;
        }
        .lavender-left-ribbon-inner {
            background-color: #fcb302;
            height: 100%;

            width: 40px;
            @media screen and (max-width: 600px) {
                width: 20px;
            }

            // position: absolute;
            z-index: 111;
        }
    }

    .lavender-right-ribbon {
        height: 500px;
        width: 50px;
        border-left: 2px solid #fcb302;
        position: absolute;
        z-index: 1;
        right: 0;
        bottom: 0px;
        @media screen and (max-width: 600px) {
            width: 25px;
        }
        .lavender-right-ribbon-inner {
            background-color: #fcb302;
            height: 100%;
            width: 40px;
            margin-left: 8px;
            // position: absolute;
            z-index: 111;
            @media screen and (max-width: 600px) {
                width: 20px;
            }
        }
    }

    .lavender-bottom-ribbon {
        height: 50px;
        width: 100%;
        border-top: 2px solid $prim-color;
        position: absolute;
        z-index: 1;
        left: 0;
        bottom: 0px;
        @media screen and (max-width: 600px) {
            height: 25px;
        }
        .lavender-bottom-ribbon-inner {
            background-color: $prim-color;
            height: 40px;
            width: 100%;
            margin-top: 8px;
            // position: absolute;
            z-index: 111;
            @media screen and (max-width: 600px) {
                height: 20px;
            }
        }
    }

    .lav-rib-wraper {
        width: 100%;
    }

    .lav-rib-wraper {
        height: 50px;
        border-bottom: 2px solid #fcb302;
        @media screen and (max-width: 600px) {
            height: 25px;
        }
    }
    .lav-rib-inner {
        background-color: #fcb302;
        height: 40px;
        right: 0;
        top: 0;
        // position: absolute;
        z-index: 111;
        @media screen and (max-width: 600px) {
            height: 20px;
        }
    }

    .lav-rib-header-name {
        width: 91%;
        margin: 15px 0px;
        @media screen and (max-width: 600px) {
            margin: 7.5px 0px;
        }
        text-transform: uppercase;
        h1 {
            color: #174b6c;
            @media screen and (max-width: 600px) {
                font-size: 1.4rem;
            }
        }
        div {
            @media screen and (max-width: 600px) {
                font-size: 0.8rem;
            }
        }
    }

    .lav-sub-header {
        background-color: $prim-color;
        min-height: 100px;
        display: flex;
        justify-content: flex-end;
        color: white;
        position: relative;
    }

    .lav-obj-heading {
        text-transform: uppercase;
        @media screen and (max-width: 600px) {
            font-size: 1rem;
        }
    }

    .lav-img-wraper {
        position: absolute;
        width: 220px;
        height: 220px;
        max-width: 30%;
        left: 60px;
        top: -75px;

        border: 1px solid gray;

        .lav-img-wraper-holder {
            width: 220px;
            height: 220px;
            position: absolute;
            overflow: hidden;
            right: 20px;
            bottom: 20px;
            background-color: gray;
            border: 5px solid white;

            img {
                width: 100%;
            }
        }
    }

    .lav-obj {
        width: 91%;
        padding: 30px 0px;
        @media screen and (max-width: 600px) {
            padding: 15px 0px;
            font-size: 0.8rem;
        }
    }

    .lavender-cv-template--header {
        min-height: 40px;
        display: flex;
        justify-content: flex-end;
        flex-direction: column !important;
        align-items: flex-end;
    }

    .lavender-cv-template--header {
        // height: 400px;
        width: 100%;
        display: flex;
        flex-direction: row;
        overflow: hidden;
        position: relative;

        .kcvt-h-l {
            flex: 4;
            background-color: #515151;
            -webkit-box-shadow: 4px 0px 5px 0px rgba(145, 145, 145, 1);
            -moz-box-shadow: 4px 0px 5px 0px rgba(145, 145, 145, 1);
            box-shadow: 4px 0px 5px 0px rgba(145, 145, 145, 1);
            color: white;
            display: flex;
            align-self: stretch;
        }
    }

    .lavender-cv-template--header-sub {
        width: 100%;
        height: 50px;
        background-color: $prim-color;
        display: flex;
        justify-content: space-around;
        .lavender-sh-s {
            display: flex;
            align-items: center;
            .lavender-sh-s-i {
                background-color: #3e3d3d;
                border-radius: 50px;
                color: $prim-color;
                padding: 5px;
                display: flex;
                margin-right: 10px;
                align-self: center;
            }

            .lavender-sh-s-c {
                color: #3e3d3d;
                a,
                a:hover {
                    color: #3e3d3d;
                    text-decoration: none;
                }
            }
        }
    }

    .top-left-ribbon {
        position: absolute;
        top: 0;
        left: 0;
        width: 550px;
        z-index: 1;
    }

    .bottom-right-ribbon {
        position: absolute;
        bottom: 0;
        right: 0;
        height: 200px;
        z-index: 1;
    }

    .bottom-left-ribbon {
        position: absolute;
        bottom: 0;
        left: 0;
        height: 100px;
        z-index: 1;
    }

    .lavender-img-container {
        width: 345px;
        height: 345px;
        background-color: #62615f;
        position: absolute;
        z-index: 2;
        border: 5px solid $prim-color;
        transform: rotate(45deg);
        top: 60px;
        left: 60px;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-content: center;
        align-items: center;
        img {
            // position: absolute;
            // top: 0;
            // left: 0;
            // right: 0;
            // bottom: 0;
            height: 100%;
            width: 100%;
            transform: rotate(315deg);
            scale: 143%;
        }
    }

    .content-wrapper {
        width: 100%;
        display: flex;
        align-content: center;
        justify-content: center;
        overflow: hidden;
        flex: 1;

        .obcv-t-l {
            .obcv-t-l-c {
                text-transform: capitalize;
                font-size: 1.1rem;
                @media screen and (max-width: 600px) {
                    font-size: 0.6rem;
                }
            }
        }
        .obcv-t-r {
            .obcv-t-r-t {
                text-transform: capitalize;
                font-size: 1.1rem;
                font-weight: normal;
                font-size: 16px;
                margin: 0px !important;
                @media screen and (max-width: 600px) {
                    font-size: 0.6rem;
                }
            }
            .obcv-t-r-c {
                font-size: 0.9rem;
                @media screen and (max-width: 600px) {
                    font-size: 0.8rem;
                }
            }
        }

        .obcv-t-content-wrapper-r {
            flex: 2;
            padding: 0px 16px !important;
            @media screen and (max-width: 600px) {
                padding: 0px 8px !important;
            }
        }
        .obcv-t-l-w {
            padding-bottom: 30px;
            @media screen and (max-width: 600px) {
                padding-bottom: 15px;
            }
        }

        .lavender-cv-template--photo {
            width: 200px;
            height: 200px;
            background-color: white;
        }

        .obcv-t {
            .MuiTimelineDot-defaultGrey {
                // background-color: #54545e;

                margin-bottom: 0px;
                margin-top: 0px;
            }

            .MuiTimelineItem-missingOppositeContent::before {
                display: none;
            }

            .obcv-t-l {
                display: flex;
                justify-content: space-between;
            }

            .obcv-t-l-c {
                font-size: 20px;
                @media screen and (max-width: 600px) {
                    font-size: 0.8rem;
                }
            }

            .obcv-t-r-t {
                margin-top: 5px;
                @media screen and (max-width: 600px) {
                    margin-top: 2.5px;
                }
            }
        }

        .lavender-achievements-holder-single-row {
            display: flex;
            justify-content: space-between;
            .obcv-t-l-w {
                padding: 0px 20px;
                @media screen and (max-width: 600px) {
                    padding: 0px 10px;
                }
            }
        }

        .lavender-cv-template--photo {
            overflow: hidden;
            img {
                width: 100%;
            }
        }

        .lavender-header-name {
            h1 {
                color: $prim-color;
                margin: 0px;
                @media screen and (max-width: 600px) {
                    font-size: 0.8rem;
                }
            }
            & > div {
                color: $prim-color;
                opacity: 0.85;
                @media screen and (max-width: 600px) {
                    font-size: 0.8rem;
                }
            }
        }

        .left-sidebar {
            background-repeat: repeat-y;
            background-size: contain;
            align-self: stretch;
            flex: 7;
            overflow: hidden;
            position: relative;
            color: white;
            display: flex;
            flex-direction: column;
            padding: 0px 50px;
            @media screen and (max-width: 600px) {
                padding: 0px 25px;
            }

            .lavender-cv-template--photo {
                align-self: center;
                margin-top: 50px;
                border-radius: 100px;
                background-color: #a4a5a9;
                @media screen and (max-width: 600px) {
                    margin-top: 25px;
                }
            }

            .lavender-cv-template--single-section {
                margin-top: 25px;
                &:last-of-type {
                    margin-bottom: 60px;
                    @media screen and (max-width: 600px) {
                        margin-bottom: 30px;
                    }
                }
                .lavender-cv-template--single-section--content {
                    margin-top: 10px;
                    @media screen and (max-width: 600px) {
                        margin-top: 5px;
                    }
                }
                .lavender-cv-template--single-section--header {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-content: flex-start;
                    margin-top: 15px;
                    @media screen and (max-width: 600px) {
                        margin-top: 7.5px;
                    }
                    .lavender-cv-template--single-section--heading {
                        margin: 0px 25px;
                        border-radius: 3px;
                        color: $prim-color;
                        text-transform: uppercase;
                        font-size: 1.4rem;
                        padding: 10px 0px;
                        @media screen and (max-width: 600px) {
                            margin: 0px 12.5px;
                            font-size: 1rem;
                            padding: 5px 0px;
                        }
                    }
                }
            }

            .lavender-cv-template--single-section--icon-list {
                display: flex;
                flex-direction: column;
                @media screen and (max-width: 600px) {
                    font-size: 0.6rem;
                }
                .lavender-cv-template--single-section--icon-list-single {
                    display: flex;
                    justify-content: flex-start;
                    padding: 5px 0px;
                    overflow: hidden;
                    margin: 0px 25px;
                    @media screen and (max-width: 600px) {
                        padding: 2.5px 0px;
                        margin: 0px 12.5px;
                    }
                    a,
                    a:hover {
                        color: #2e2e2e;
                        text-decoration: none;
                    }
                    .lavender-cv-template--single-section--icon-list-single-icon {
                        background-color: white;
                        color: $prim-color;
                        // padding: 5px 30px 5px 10px;
                        padding: 8px;
                        border-radius: 30px;
                        display: flex;
                        margin-right: 15px;
                        align-self: center;
                        background-repeat: no-repeat;
                        background-size: cover;
                        background-position: center;
                        @media screen and (max-width: 600px) {
                            padding: 4px;
                            margin-right: 7.5px;
                        }
                    }

                    .lavender-cv-template--single-section--icon-list-single-content {
                        display: flex;
                        justify-content: center;
                        color: #2e2e2e;
                        align-content: center;
                        align-items: center;
                    }
                }
            }

            .lavender-cv-template--single-section--skill-list,
            .lavender-cv-template--single-section--keyassets-list {
                display: flex;
                overflow: hidden;
                flex-direction: column;
                padding: 5px 25px;
                @media screen and (max-width: 600px) {
                    padding: 2.5px 12.5px;
                }

                &.l-u {
                    .lavender-cv-template--single-section--skill-list-single-skill {
                        color: #2e2e2e;
                        // &::before {
                        //     content: "•";
                        //     float: left;
                        //     display: inline-block;
                        //     font-weight: bold;
                        //     font-size: 30px;
                        //     line-height: 1;
                        //     margin: -5px 10px 0px 0px;
                        // }
                    }
                }

                .lavender-cv-template--single-section--skill-list-single {
                    justify-content: space-between;
                    display: flex;
                    flex-direction: row;
                    width: 100%;
                    // padding: 5px 50px;
                    @media screen and (max-width: 600px) {
                        font-size: 0.6rem;
                    }

                    .lavender-cv-template--single-section--skill-list-single-stars {
                        color: white;
                    }
                }
                &.lavender-cv-template--single-section--keyassets-list {
                    flex-direction: row;
                    flex-wrap: wrap;
                    justify-content: flex-start;
                    .lavender-cv-template--single-section--skill-list-single {
                        width: auto;
                        padding: 5px 10px;
                        @media screen and (max-width: 600px) {
                            padding: 2.5px 5px;
                        }
                    }
                }
            }

            .lavender-cv-template--single-section--language-list {
                display: flex;
                flex-direction: column;
                padding: 0px 25px;
                @media screen and (max-width: 600px) {
                    padding: 0px 12.5px;
                }

                .lavender-cv-template--single-section--language-list-single {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-content: center;
                    align-items: center;
                    margin: 5px 0px;
                    @media screen and (max-width: 600px) {
                        margin: 2.5px 0px;
                        font-size: 0.6rem;
                    }
                    progress {
                        border-radius: 0;
                        height: 7px;
                        @media screen and (max-width: 600px) {
                            height: 3.5px;
                            width: 60px;
                        }
                        &::-webkit-progress-value,
                        &::-moz-progress-bar {
                            background: $prim-color !important;
                        }
                    }
                    .lavender-cv-template--single-section--language-list-single-content {
                        text-overflow: ellipsis;
                        overflow: hidden;
                        white-space: nowrap;
                        color: #2e2e2e;
                    }
                    .progress-circle-container {
                        display: flex;
                    }
                    .progress-circle {
                        position: relative;
                        display: inline-block;
                        width: 60px;
                        height: 60px;
                        border-radius: 50%;
                        background-color: rgba(85, 86, 88, 1);
                        border: 1px solid rgba(255, 255, 255, 0.2);
                        @media screen and (max-width: 600px) {
                            width: 30px;
                            height: 30px;
                        }
                    }
                }
            }
        }
        .obcv-t-l-d {
            text-align: center;
            @media screen and (max-width: 600px) {
                font-size: 0.6rem;
            }
        }

        .obcv-t {
            padding: 0px;
        }

        .main-content {
            padding: 0px 50px 0px 50px;
            // overflow: hidden;
            flex: 8;
            background-repeat: repeat-y;
            background-size: contain;
            background-color: #f7f7f7;
            @media screen and (max-width: 600px) {
                padding: 0px 25px 0px 25px;
            }

            .main-content-header {
                height: 135px;
                width: 100%;
                margin-top: 100px;
                margin-left: -3px;
                background-repeat: no-repeat;
                background-size: 100%;
                background-position: center;
                display: flex;
                @media screen and (max-width: 600px) {
                    height: 65px;
                }

                .lavender-cv-template--header--name {
                    align-self: center;
                    display: flex;
                    flex: 1;
                    flex-direction: column;
                    // align-items: center;
                    padding: 0px 100px;
                    @media screen and (max-width: 600px) {
                        padding: 0px 50px;
                    }
                    h1 {
                        text-transform: uppercase;
                        margin: 0;
                        font-weight: lighter;
                        color: white;
                        line-height: 1;
                        font-size: 3.2rem;
                        @media screen and (max-width: 600px) {
                            font-size: 1.6rem;
                        }
                    }
                    span {
                        color: white;
                        text-transform: uppercase;
                        line-height: 2;
                        font-weight: lighter;
                    }
                }
            }

            .main-content-wrapper {
            }

            .main-content--single-section {
                margin-top: 50px;
                @media screen and (max-width: 600px) {
                    margin-top: 25px;
                }
                &:last-of-type {
                    margin-bottom: 100px;
                    @media screen and (max-width: 600px) {
                        margin-bottom: 50px;
                    }
                }
                .main-content--single-section-heading {
                    font-size: 1.8rem;
                    display: inline-block;
                    align-content: center;
                    align-items: center;
                    margin-bottom: 30px;
                    background-color: $prim-color;
                    color: white;
                    text-transform: uppercase;
                    position: relative;

                    margin-left: -70px;
                    @media screen and (max-width: 600px) {
                        font-size: 0.9rem;
                        margin-bottom: 15px;
                        margin-left: -40px;
                    }
                    &::after {
                        content: " ";
                        display: block;
                        width: 100%;
                        border: 1px solid $prim-color;
                        position: absolute;
                        height: 100%;
                        left: 5px;
                        top: 5px;
                    }
                    span {
                        word-spacing: 2px;
                        letter-spacing: 2.5px;
                        position: relative;
                        z-index: 1;
                        font-size: 18px;
                        display: block;
                        padding: 10px 10px;
                        @media screen and (max-width: 600px) {
                            font-size: 0.8rem;
                            padding: 5px 5px;
                        }
                    }
                }
                .main-content--single-section-content {
                    &.job-list {
                        .main-content--single-section-content-single-job {
                            margin-top: 20px;

                            .main-content--single-section-content-single-job-description {
                                padding-left: 25px;
                            }

                            .main-content--single-section-content-single-job-company {
                                color: #515151;
                                font-weight: bold;
                                &::before {
                                    content: " ";
                                    float: left;
                                    display: inline-block;
                                    font-weight: bold;
                                    height: 30px;
                                    width: 15px;
                                    line-height: 1;
                                    margin: -5px 10px 0px 0px;
                                }
                            }
                            .main-content--single-section-content-single-job-title {
                                color: #515151;
                                font-weight: bold;
                                font-size: 20px;
                                &::before {
                                    content: "•";
                                    float: left;
                                    display: inline-block;
                                    font-weight: bold;
                                    font-size: 30px;
                                    line-height: 1;
                                    margin: -5px 10px 0px 0px;
                                }
                            }
                        }
                    }

                    &.education-list {
                        .main-content--single-section-content-single-education {
                            margin-top: 10px;

                            .main-content--single-section-content-single-education-description {
                                padding-left: 25px;
                            }

                            .main-content--single-section-content-single-education-company {
                                color: #515151;
                                font-weight: bold;
                                &::before {
                                    content: "";
                                    float: left;
                                    display: inline-block;
                                    font-weight: bold;
                                    width: 15px;
                                    height: 30px;
                                    line-height: 1;
                                    margin: -5px 10px 0px 0px;
                                }
                            }
                            .main-content--single-section-content-single-education-title {
                                color: #515151;
                                font-weight: bold;
                                font-size: 20px;
                                &::before {
                                    content: "•";
                                    float: left;
                                    display: inline-block;
                                    font-weight: bold;
                                    font-size: 30px;
                                    line-height: 1;
                                    margin: -5px 10px 0px 0px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
