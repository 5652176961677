@import "~bootstrap/scss/bootstrap.scss";
@import "./vars";

.App,
#root {
  height: 100%;
}

label {
  margin-bottom: 0px;
}

body,
html {
  height: 100%;
}

.mh-100 {
  min-height: 100%;
}

.header-margin {
  width: 100%;
  height: 15px;
}

.w-90 {
  width: 90%;
}

.w-85 {
  width: 85%;
}

.pointer {
  cursor: pointer !important;
}

li.MuiListItem-root[role="menuitem"] {
  color: $primary-bg !important;
}

.hidden-m {
  position: absolute;
  margin-left: -9999999px;
}

* {
  transition: 0.2s;
}

.n-link {
  color: $primary-color;
  text-decoration: none;

  &:hover {
    color: $primary-bg;
    text-decoration: none;
  }
}

.spinner {
  animation: spin infinite 5s linear;

  /*You can increase or decrease the timer (5s) to 
   increase or decrease the speed of the spinner*/
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
