@import "../../vars";

@media only screen and (max-width: 768px) {
    .phs-bg-holder {
        .navbar-main {
            #collapsibleNavbar {
                background-color: #2e2e2e;
            }
            .navbar-toggler {
                svg {
                    color: white;
                }
            }
        }
    }
}

.navbar-main {
    height: 80px;
    // background-color: grey;
    .MuiButtonBase-root {
        .MuiButton-label {
            font-weight: 400;
        }
    }
    .navbar-brand {
        padding: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        h4 {
            color: $primary-bg;
            margin: 0px;
            margin-left: 20px;
            font-size: 23px;
        }
        img {
            width: 40px;
        }
    }

    .men-li-btn {
        padding: 0px 5px !important;
        color: $primary-color;
        .MuiButton-label {
            text-transform: initial !important;
            padding-right: 0.5rem;
            padding-left: 0.5rem;
        }
    }

    .nav-item {
        padding: 0px 5px;
        color: $primary-color;
        a {
            color: $primary-color;
            &.btn {
                padding-left: 15px;
                padding-right: 15px;
                font-size: 14px;
                font-weight: bolder;
            }
            &:hover {
                color: $primary-bg;
            }
        }
    }

    .active-nav {
        font-weight: bold;
        //color: #57c469 !important;
    }

    #collapsibleNavbar {
        display: flex;
        justify-content: space-between !important;
    }

    &.fixed-top,
    &.fixed-bottom {
        background-color: white;
        width: 100%;
        padding-left: 7.5%;
        padding-right: 7.5%;
        height: 65px;

        .navbar-brand {
            h4 {
                font-size: 20px;
            }
            img {
                width: 35px;
            }
        }
    }

    &.fixed-top {
        -webkit-box-shadow: 0px 1px 1px -1px rgba(140, 140, 140, 1);
        -moz-box-shadow: 0px 1px 1px -1px rgba(140, 140, 140, 1);
        box-shadow: 0px 1px 1px -1px rgba(140, 140, 140, 1);
    }

    &.fixed-bottom {
        -webkit-box-shadow: 0px -1px 1px -1px rgba(140, 140, 140, 1);
        -moz-box-shadow: 0px -1px 1px -1px rgba(140, 140, 140, 1);
        box-shadow: 0px -1px 1px -1px rgba(140, 140, 140, 1);
    }

    @media only screen and (max-width: 1080px) {
        // position: initial !important;

        .navbar-toggler:not(.collapsed) {
            position: relative;
            z-index: 102;
        }

        .navbar-toggler {
            display: block !important;
        }

        .nav-item {
            padding: 0px 0px;
            text-align: center;
            font-size: 1.5rem;
            .nav-link.btn.btn-lg.btn-primary {
                background-image: none !important;
                background-color: transparent !important;
                text-align: center;
                padding: 0px;
                font-weight: normal;
                height: 40px;
                line-height: 2.5rem;
            }
        }

        #collapsibleNavbar {
            position: fixed;
            top: 0px;
            bottom: 0px;
            width: 100%;
            margin: 0px !important;
            padding: 0px !important;
            left: 0px;
            background-color: white;
            z-index: 101;
            flex-direction: column;
            justify-content: center !important;

            &.collapse {
                display: none !important;
                &.show {
                    display: flex !important;
                }
            }
            .navbar-nav {
                // height: 100%;
                display: flex;
                justify-content: center;
                align-content: center;
                align-items: center;
                flex-direction: column;
            }

            .nav-right-men {
                display: flex;
                flex-direction: column;
                button {
                    margin: 10px 0px;
                }
            }
        }
    }

    .nav-right-men {
        display: flex;
        button {
            margin-left: 10px;
            padding: 8px 30px;
            white-space: nowrap;
        }
    }

    .navbar-toggler {
        svg {
            color: black;
        }
    }
}
