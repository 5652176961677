#coal-template-cv {
    $prim-color: #54545e;
    // padding: 30px;
    width: 100%;
    min-width: 100%;
    color: #54545e;
    display: flex;
    flex-direction: column;

    .obcv-profession {
        background-color: $prim-color;
        color: rgba(255, 255, 255, 0.88);
        padding: 2px 10px 2px 50px;
        font-size: 1.3rem;
        @media screen and (max-width: 600px) {
            font-size: 1rem;
        }
    }

    .coal-cv-template--header--image-container-inner {
        width: 100%;
        height: 100%;
        border: 7px solid #a28c60;
        border-radius: 72px;
        position: absolute;
        z-index: 99;
        background-color: white;
    }

    .coal-cv-template--single-section--content--bottom-line {
        height: 17px;
        background-color: #a28c60;
        margin-bottom: 50px;
        border-top: 5px solid white;
        border-bottom: 5px solid white;
        position: absolute;
        left: 0;
        right: 0;
        margin-top: -50px;
    }

    .obcv-hero {
        display: flex;
        margin-bottom: 50px;
        .obcv-hero-img {
            background-color: #54545e;
        }
        .obcv-hero-content {
            padding: 50px 0px 25px 0px;
            text-transform: uppercase;
            border-bottom: 3px solid rgba(0, 0, 0, 0.5);
            @media screen and (max-width: 600px) {
                padding: 25px 0px 12.5px 0px;
            }
            .obcv-hero-content-title {
                font-weight: normal;
                margin-bottom: 5px;
            }
            .obcv-hero-content-title-border {
                display: block;
                height: 3px;
                background: $prim-color;
                width: 100px;
                margin: 0px 0px 10px 5px;
                @media screen and (max-width: 600px) {
                    height: 1.5px;
                    // padding: 25px 0px 12.5px 0px;
                    margin: 0px 0px 5px 2.5px;
                }
            }
        }
    }

    .MuiTimelineItem-missingOppositeContent {
        &::before {
            display: none !important;
        }
    }

    .obcv-t-l {
        .obcv-t-l-c {
            text-transform: uppercase;
            font-size: 1.1rem;
            @media screen and (max-width: 600px) {
                font-size: 0.8rem;
            }
        }
    }
    .obcv-t-r {
        .obcv-t-r-t {
            text-transform: uppercase;
            font-size: 1.1rem;
            font-weight: normal;
            @media screen and (max-width: 600px) {
                font-size: 0.8rem;
            }
        }
        .obcv-t-r-c {
            font-size: 0.9rem;
            @media screen and (max-width: 600px) {
                font-size: 0.7rem;
            }
        }
    }

    .obcv-t-content-wrapper-r {
        flex: 2;
        color: $prim-color;
        .obcv-t-l-c {
            font-weight: bold;
            @media screen and (max-width: 600px) {
                font-size: 0.8rem;
            }
        }
    }

    .obcv-timeline-connector {
        display: flex;
        flex-direction: column;
        color: $prim-color;
        width: 200px;
        @media screen and (max-width: 600px) {
            width: 100px;
            font-size: 0.8rem;
        }
        span {
            text-align: center;
        }
    }

    .obcv-t {
        .MuiTimelineDot-defaultGrey {
            background-color: #54545e;
            padding: 6px;
            margin-bottom: 0px;
            margin-top: 0px;
            @media screen and (max-width: 600px) {
                padding: 3px;
            }
        }
        .MuiTimelineConnector-root {
            background-color: #54545e;
            width: 3px;
        }
    }

    .content-wrapper {
        width: 100%;
        display: flex;
        align-content: center;
        justify-content: center;
        overflow: hidden;
        flex: 1;

        .coal-cv-template--header {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            // overflow: hidden;
            padding: 20px 0px;
            background-color: $prim-color;
            color: white;
            margin-bottom: 50px;

            justify-content: space-between;
            position: relative;

            &::after {
                content: "";
                position: absolute;
                height: 20px;
                width: 20px;
                bottom: -3px;
                background-color: #54545e;
                left: -13px;
                border-radius: 50px;
            }

            .coal-cv-template--header--name {
                display: flex;
                flex-direction: column;
                padding: 0px 0px;
                flex: 2;
                text-align: center;
                h1 {
                    margin: 0;
                    // &:nth-of-type(2) {
                    //     text-align: center;
                    // }
                    font-size: 4rem;
                    text-transform: uppercase;
                    @media screen and (max-width: 600px) {
                        font-size: 3rem;
                    }
                }
                div {
                    text-align: center;
                    span:first-of-type {
                        color: #c87e2a;
                    }
                    @media screen and (max-width: 600px) {
                        font-size: 1rem;
                    }
                }
            }
        }

        .coal-cv-template--single-section:nth-of-type(2) {
            margin-top: 100px;
        }

        .coal-cv-template--single-section {
            padding: 0px 15px;
            color: white;
            @media screen and (max-width: 600px) {
                padding: 0px 7.5px;
            }

            &:not(:last-of-type) {
                margin-bottom: 30px;
                @media screen and (max-width: 600px) {
                    margin-bottom: 15px;
                }
            }

            .coal-cv-template--single-section--content {
                padding: 0px 20px;
                @media screen and (max-width: 600px) {
                    padding: 0px 10px;
                    font-size: 0.8rem;
                }
            }

            .coal-cv-template--single-section--header {
                display: flex;
                flex-direction: row;
                align-content: center;
                margin-bottom: 10px;
                justify-content: space-between;
                @media screen and (max-width: 600px) {
                    margin-bottom: 5px;
                }
                .coal-cv-template--single-section--heading {
                    &::before {
                        content: " ";
                        margin-right: 10px;
                        display: inline-block;
                        margin-left: 10px;
                        background-color: white;
                        width: 6px;
                        height: 35px;
                        border-radius: 5px;
                        float: left;
                        margin-top: 0px;
                        @media screen and (max-width: 600px) {
                            height: 17px;
                            width: 3px;
                            margin-right: 5px;
                            margin-left: 5px;
                        }
                    }
                    align-self: center;
                    border-radius: 3px;
                    font-size: 1.8rem;
                    text-transform: uppercase;
                    margin: 3px 0px;
                    @media screen and (max-width: 600px) {
                        font-size: 1rem;
                        margin: 1.5px 0px;
                    }
                }

                .coal-cv-template--single-section--header-icon {
                    display: flex;
                    align-content: center;
                    justify-content: center;
                    align-items: center;
                }
            }
        }

        .left-sidebar {
            flex: 4;
            overflow: hidden;
            background-color: $prim-color;
            align-self: stretch;
            // display: flex;
            // flex-direction: column;
            // justify-content: flex-start;

            .coal-cv-template--header--image-wrapper {
                flex: 1;
                display: flex;
                justify-content: center;
                margin-bottom: 50px;
                position: relative;
                min-height: 150px;
                .coal-cv-template--header--image-container {
                    background-color: white;
                    width: 150px;
                    height: 150px;
                    border-radius: 100px;
                    position: absolute;
                    margin-top: 70px;
                    // z-index: 99;
                    border: 7px solid white;
                    @media screen and (max-width: 600px) {
                        margin-top: 50px;
                    }
                }
            }

            .coal-cv-template--single-section--icon-list {
                display: flex;
                flex-direction: column;
                .coal-cv-template--single-section--icon-list-single {
                    display: flex;
                    justify-content: flex-start;
                    padding: 5px 0px;
                    overflow: hidden;
                    @media screen and (max-width: 600px) {
                        padding: 2.5px 0px;
                    }
                    a,
                    a:hover {
                        color: white;
                        text-decoration: none !important;
                        @media screen and (max-width: 600px) {
                            font-size: 0.6rem;
                        }
                    }
                    .coal-cv-template--single-section--icon-list-single-icon {
                        background-color: #54545e;
                        border-radius: 5px;
                        color: white;
                        padding: 5px;
                        display: flex;
                        margin-right: 10px;
                        align-self: center;
                        opacity: 0.95;
                        @media screen and (max-width: 600px) {
                            margin-right: 5px;
                            padding: 2.5px;
                        }
                    }
                    .coal-cv-template--single-section--icon-list-single-content {
                        @media screen and (max-width: 600px) {
                            font-size: 0.6rem;
                        }
                    }
                }
            }

            .coal-cv-template--single-section--skill-list,
            .coal-cv-template--single-section--keyassets-list {
                display: flex;
                overflow: hidden;
                flex-direction: column;

                .coal-cv-template--single-section--skill-list-single {
                    justify-content: space-between;
                    display: flex;
                    flex-direction: row;
                    width: 100%;
                    padding: 5px 0px;
                    align-items: center;
                    @media screen and (max-width: 600px) {
                        padding: 2.5px 0px;
                    }

                    .coal-cv-template--single-section--skill-list-single-stars {
                        min-width: 130px;
                        svg {
                            margin: 0px 3px;
                            @media screen and (max-width: 600px) {
                                margin: 0px 0px;
                            }
                        }
                    }
                    .coal-cv-template--single-section--skill-list-single-skill{
                        @media screen and (max-width: 600px) {
                            margin-right: 2.5px;
                            font-size: 0.6rem;
                        }
                    }
                }
                &.coal-cv-template--single-section--keyassets-list {
                    flex-direction: row;
                    flex-wrap: wrap;
                    justify-content: center;
                    .coal-cv-template--single-section--skill-list-single {
                        width: auto;
                        padding: 5px 10px;
                        @media screen and (max-width: 600px) {
                            padding: 2.5px 5px;
                        }
                    }
                }
            }

            .coal-cv-template--single-section--language-list {
                display: flex;
                justify-content: space-around;
                flex-flow: row wrap;
                // padding: 0px 5px;
                @media screen and (max-width: 600px) {
                    padding: 0px 2.5px;
                }

                .coal-cv-template--single-section--language-list-single {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-content: center;
                    align-items: center;
                    margin: 5px 0px;
                    @media screen and (max-width: 600px) {
                        margin: 2.5px 0px;
                    }
                    .coal-cv-template--single-section--language-list-single-content {
                        text-overflow: ellipsis;
                        overflow: hidden;
                        white-space: nowrap;
                        @media screen and (max-width: 600px) {
                            font-size: 9px;
                        }
                    }
                    .progress-circle-container {
                        display: flex;
                    }
                    .progress-circle {
                        position: relative;
                        display: inline-block;
                        width: 60px;
                        height: 60px;
                        border-radius: 50%;
                        background-color: white;
                        @media screen and (max-width: 600px) {
                            width: 30px;
                            height: 30px;
                        }
                    }
                    .progress-circle:after {
                        content: "";
                        display: inline-block;
                        width: 100%;
                        height: 100%;
                        border-radius: 50%;
                        -webkit-animation: colorload 2s;
                        animation: colorload 2s;
                    }
                    .progress-circle span {
                        font-size: 1rem;
                        color: $prim-color;
                        position: absolute;
                        background-color: white !important;
                        left: 50%;
                        top: 50%;
                        display: block;
                        width: 45px;
                        height: 45px;
                        line-height: 45px;
                        margin-left: -22.5px;
                        margin-top: -22.5px;
                        text-align: center;
                        border-radius: 50%;
                        font-size: 12px;
                        z-index: 1;
                        border: 1px solid $prim-color;
                        @media screen and (max-width: 600px) {
                            font-size: 0.5rem;
                            width: 22.5px;
                            height: 22.5px;
                            line-height: 22.5px;
                            font-size: 6px;
                            margin-left: -11px;
                            margin-top: -11px;
                        }
                    }
                    .progress-circle span:after {
                        content: "%";
                        font-weight: 600;
                        font-size: 12px;
                        color: $prim-color;
                        @media screen and (max-width: 600px) {
                            font-weight: 300;
                            font-size: 6px;
                        }
                    }
                    .progress-circle.progress-25:after {
                        background-image: linear-gradient(90deg, rgba(85, 86, 88, 1) 50%, transparent 50%, transparent),
                            linear-gradient(180deg, white 50%, rgba(85, 86, 88, 1) 50%, rgba(85, 86, 88, 1));
                    }
                    .progress-circle.progress-50:after {
                        background-image: linear-gradient(-90deg, white 50%, transparent 50%, transparent),
                            linear-gradient(270deg, white 50%, rgba(85, 86, 88, 1) 50%, rgba(85, 86, 88, 1));
                    }
                    .progress-circle.progress-75:after {
                        background-image: linear-gradient(0deg, white 50%, transparent 50%, transparent),
                            linear-gradient(270deg, white 50%, rgba(85, 86, 88, 1) 50%, rgba(85, 86, 88, 1));
                    }
                    .progress-circle.progress-100:after {
                        background-image: linear-gradient(90deg, white 50%, transparent 50%, transparent),
                            linear-gradient(270deg, white 50%, rgba(85, 86, 88, 1) 50%, rgba(85, 86, 88, 1));
                    }
                }
            }
        }

        .main-content {
            overflow: hidden;
            flex: 9;
            margin-left: -2px;
            // padding: 0px 0px 10px 30px;

            .coal-cv-template--single-section {
                padding: 0px;

                .coal-cv-template--single-section--header {
                    position: relative;
                    &::before {
                        content: "";
                        position: absolute;
                        height: 20px;
                        width: 20px;
                        top: -2px;
                        background-color: $prim-color;
                        left: -15px;
                        border-radius: 50px;
                    }

                    &::after {
                        content: "";
                        position: absolute;
                        height: 20px;
                        width: 20px;
                        bottom: -2px;
                        background-color: $prim-color;
                        left: -15px;
                        border-radius: 50px;
                    }

                    .coal-cv-template--single-section--heading {
                        width: 80%;
                        padding: 10px 0px 10px 50px;
                        border-radius: 20px;
                        border-top-left-radius: 0px;
                        border-bottom-left-radius: 0px;
                        display: flex;
                        justify-content: space-between;
                        @media screen and (max-width: 600px) {
                            padding: 5px 0px 5px 25px;
                        }
                        &::before {
                            display: none;
                        }
                        background-color: $prim-color;
                        .coal-cv-template--single-section--heading-icon {
                            margin: 0px 10px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            background-color: white;
                            border-radius: 50px;
                            color: $prim-color;
                            padding: 5px;
                            @media screen and (max-width: 600px) {
                                margin: 0px 5px;
                            }
                        }
                    }
                }
            }

            .main-content--single-section {
                &:not(:last-of-type) {
                    margin-bottom: 30px;
                }
                .main-content--single-section-heading {
                    color: $prim-color;
                    border-bottom: 5px solid $prim-color;
                    padding-bottom: 15px;
                }
                .main-content--single-section-content {
                    &.job-list {
                        .main-content--single-section-content-single-job {
                            margin-top: 20px;

                            .main-content--single-section-content-single-job-company {
                                color: $prim-color;
                                font-weight: bold;
                            }
                            .main-content--single-section-content-single-job-title {
                                color: $prim-color;
                                font-weight: bold;
                                font-size: 20px;
                                @media screen and (max-width: 600px) {
                                    font-size: 10px;
                                }
                            }
                        }
                    }

                    &.education-list {
                        .main-content--single-section-content-single-education {
                            margin-top: 10px;

                            .main-content--single-section-content-single-education-company {
                                color: $prim-color;
                                font-weight: bold;
                            }
                            .main-content--single-section-content-single-education-title {
                                color: $prim-color;
                                font-weight: bold;
                                font-size: 20px;
                                @media screen and (max-width: 600px) {
                                    font-size: 10px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
