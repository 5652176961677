#pollen-template-cv {
    $prim-color: #42565f;
    $color1: #fae385;
    // padding: 30px;
    width: 100%;
    min-width: 100%;
    display: flex;
    flex-direction: column;

    .pollen-contact-section {
        color: $prim-color;
        .pollen-cv-template--single-section--icon-list-single {
            display: flex;
            margin: 10px 0px;
            @media screen and (max-width: 600px) {
                margin: 5px 0px;
                font-size: 0.6rem;
            }
            .pollen-cv-template--single-section--icon-list-single-icon {
                margin-right: 20px;
                @media screen and (max-width: 600px) {
                    margin-right: 10px;
                }
            }
        }
    }

    .pollen-cv-template--single-section--icon-list-single-content {
        a {
            color: white;
            text-decoration: none;
        }
    }

    .pollen-image-holder {
        width: 200px;
        height: 200px;
        background-color: $color1;
        border-radius: 120px;
        position: absolute;
        left: 160px;
        top: -195px;
        z-index: 11;
        overflow: hidden;
        // border: 1px solid $color1;
        -webkit-box-shadow: 0px 0px 12px 8px rgba(250, 227, 133, 0.32);
        -moz-box-shadow: 0px 0px 12px 8px rgba(250, 227, 133, 0.32);
        box-shadow: 0px 0px 12px 8px rgba(250, 227, 133, 0.32);
        @media screen and (max-width: 600px) {
            left: 20px;
            width: 150px;
            height: 150px;
            top: -165px;
        }
        img {
            width: 100%;
        }
    }

    .pollen-cv-template--single-section--header-underline {
        height: 0px;
        border-top: 1px solid $color1;
        margin-top: -11.5px;
        margin-bottom: 10px;
    }

    .pollen-cv-template--single-section--skill-list-single-stars {
        display: flex;
    }
    .pollen-rect {
        width: 20px;
        height: 10px;
        display: block;
        margin: 0px 5px;
        @media screen and (max-width: 600px) {
            width: 10px;
            height: 5px;
            margin: 0px 2.5px;
        }
        &.pollen-rect-full {
            background-color: $color1;
        }

        &.pollen-rect-half {
            background: rgb(250, 227, 133);
            background: linear-gradient(
                90deg,
                rgba(250, 227, 133, 1) 0%,
                rgba(250, 227, 133, 1) 50%,
                rgba(255, 255, 255, 1) 50%
            );
        }

        &.pollen-rect-emp {
            background-color: white;
        }
    }

    .pollen-profile-section {
        display: flex;
        position: relative;
        margin-top: 40px;
        margin-bottom: 40px;

        .pollen-bottom-tri {
            display: inline-block;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 0px 40px 0;
            border-color: transparent white transparent transparent;
            position: absolute;
            bottom: -79px;
            left: 0;
        }

        .pollen-top-tri {
            content: "";
            display: inline-block;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 40px 0 0 0px;
            border-color: transparent transparent transparent white;
            position: absolute;
            top: -79px;
            left: 0;
        }

        .pollen-objective-holder {
            flex: 2;
        }
        .pollen-contact-section {
            flex: 1;
        }

        .pollen-objective-holder {
            padding: 10px 50px;
            color: $prim-color;
            @media screen and (max-width: 600px) {
                padding: 5px 25px;
            }
        }
    }

    .pollen-cv-template--header {
        width: 100%;
        align-items: center;
        overflow: hidden;

        .pollen-color-cols-holder {
            display: flex;
            .p-cc-h-w,
            .p-cc-h-b,
            .p-cc-h-y {
                display: flex;

                &.p-cc-h-w {
                    background-color: white;
                    flex: 5;
                }
                &.p-cc-h-b {
                    background-color: $prim-color;
                    flex: 2;
                }
                &.p-cc-h-y {
                    background-color: $color1;
                    flex: 11;
                    padding-top: 50px;
                    @media screen and (max-width: 600px) {
                        padding-top: 25px;
                    }

                    .pollen-cv-template--header--name {
                        color: $prim-color;
                        h1{
                            @media screen and (max-width: 600px) {
                                font-size: 1.6rem;
                            }
                        }
                        span {
                            color: $prim-color;
                            @media screen and (max-width: 600px) {
                                font-size: 0.8rem;
                            }
                        }
                    }
                }
            }
        }

        .pollen-cv-template--header--name {
            display: flex;
            flex-direction: column;
            padding: 40px;
            h1 {
                margin: 0;
                // &:nth-of-type(2) {
                //     text-align: center;
                // }
            }
        }

        .pollen-cv-template--header--img {
            // margin-right: 110px;
            overflow: hidden;
            position: relative;
            padding: 30px 40px;
            height: 100%;
        }

        .hexagon {
            overflow: hidden;
            visibility: hidden;
            -webkit-transform: rotate(120deg);
            -moz-transform: rotate(120deg);
            -ms-transform: rotate(120deg);
            -o-transform: rotate(120deg);
            transform: rotate(120deg);
            cursor: pointer;
        }
        .hexagon-in1 {
            overflow: hidden;
            width: 100%;
            height: 100%;
            -webkit-transform: rotate(-60deg);
            -moz-transform: rotate(-60deg);
            -ms-transform: rotate(-60deg);
            -o-transform: rotate(-60deg);
            transform: rotate(-60deg);
        }
        .hexagon-in2 {
            width: 100%;
            height: 100%;
            background-repeat: no-repeat;
            background-position: 50%;
            visibility: visible;
            transform: rotate(-60deg);
        }

        .hexagon2 {
            width: 150px;
            height: 300px;
            margin: -80px 0 0 20px;
        }
    }

    .obcv-t {
        margin-top: 50px;
        .obcv-t-l {
            position: relative;
            top: -29px;
        }

        .obcv-t-content-wrapper-r {
            flex: 5;
            position: relative;
            top: -34px;
            color: $prim-color;
        }
        .obcv-t-r-c {
            margin-bottom: 50px;
            @media screen and (max-width: 600px) {
                font-size: 0.8rem;
            }
        }
        .obcv-t-l-c {
            span {
                font-weight: bold;
                @media screen and (max-width: 600px) {
                    font-size: 0.8rem;
                }
            }
        }
        .MuiTimelineItem-root {
            &:last-of-type {
                .MuiTimelineSeparator-root {
                    display: none;
                }
                .obcv-t-content-wrapper-r {
                    .obcv-t-r-c {
                        margin-bottom: 0;
                    }
                }
            }
        }
        .obcv-t-l-d {
            position: relative;
            // margin-right: 20px;
            color: $prim-color;
            @media screen and (max-width: 600px) {
                font-size: 0.8rem;
            }
            &::after {
                content: "";
                position: absolute;
                width: 20px;
                height: 1px;
                right: -16px;
                bottom: 0px;
                background-color: $prim-color;
                @media screen and (max-width: 600px) {
                    bottom: -5px;
                }
            }
        }

        .MuiTimelineConnector-root {
            background-color: $prim-color;
            width: 1px;
        }
        .MuiTimelineSeparator-root {
            position: relative;
        }
        .MuiTimelineDot-root {
            display: none;
        }
    }

    .pollen-cv-template--single-section--header:not(.y-bg) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        margin-bottom: 10px;
        .pollen-cv-template--single-section--heading {
            align-self: flex-start;
            border-radius: 3px;
            color: white;
            padding: 10px 50px;
            @media screen and (max-width: 600px) {
                padding: 5px 50px;
                font-size: 1rem;
            }
        }

        .pollen-cv-template--single-section--header-underline {
            border-top: 1px solid $color1;
        }
    }

    .y-bg {
        position: relative;
        z-index: 1;
        padding: 0px 20px;
        @media screen and (max-width: 600px) {
            padding: 0px 10px;
        }
        h3 {
            position: relative;
            z-index: 1;
            @media screen and (max-width: 600px) {
                font-size: 1rem;
            }
        }
        &::before {
            content: "";
            position: absolute;
            left: 0px;
            top: 0px;
            bottom: 0px;
            width: 110px;
            background-color: $color1;
            z-index: 0;
            @media screen and (max-width: 600px) {
                width: 55px;
            }
        }
    }
    .pollen-cv-template--single-section--header,
    .pollen-cv-template--header-sub-heading {
        text-transform: uppercase;
    }
    .pollen-cv-template--header-sub-content {
        @media screen and (max-width: 600px) {
            font-size: 0.8rem;
        }
    }

    .content-wrapper {
        width: 100%;
        display: flex;
        align-content: center;
        justify-content: center;
        overflow: hidden;
        flex: 1;
        align-self: stretch;

        .divider_ {
            min-height: 100%;
            background-color: $prim-color;
            width: 5px;
            background-color: $prim-color;
            margin: 33px 33px 33px 40px;
        }

        .left-sidebar {
            flex: 3;
            overflow: hidden;
            padding: 0px 0px;
            background-color: $prim-color;
            padding-top: 40px;
            align-self: stretch;

            .obcv-t {
                color: white;
                .obcv-t-r-c,
                .obcv-t-l-c,
                .obcv-t-l-d {
                    color: white;
                }
                .obcv-t-l-d {
                    &::after {
                        background-color: $color1;
                    }
                }
                .MuiTimelineSeparator-root {
                    .MuiTimelineConnector-root {
                        background-color: $color1;
                    }
                }
            }
            .pollen-cv-template--single-section {
                color: white;
                margin-bottom: 30px;

                .pollen-cv-template--single-section--content {
                    padding: 0px 50px;
                    @media screen and (max-width: 600px) {
                        padding: 0px 25px;
                    }
                }
            }

            .pollen-cv-template--single-section--icon-list {
                display: flex;
                flex-direction: column;
                .pollen-cv-template--single-section--icon-list-single {
                    display: flex;
                    justify-content: flex-start;
                    padding: 5px 0px;
                    overflow: hidden;
                    @media screen and (max-width: 600px) {
                        padding: 2.5px 0px;
                        font-size: 0.6rem;
                    }
                    .pollen-cv-template--single-section--icon-list-single-icon {
                        background-color: #555658;
                        border-radius: 50px;
                        color: white;
                        padding: 5px;
                        display: flex;
                        margin-right: 20px;
                        align-self: center;
                    }
                }
            }

            .pollen-cv-template--single-section--skill-list,
            .pollen-cv-template--single-section--keyassets-list {
                display: flex;
                overflow: hidden;
                flex-direction: column;

                .pollen-cv-template--single-section--skill-list-single {
                    justify-content: space-between;
                    display: flex;
                    flex-direction: row;
                    width: 100%;
                    padding: 5px 0px;
                    @media screen and (max-width: 600px) {
                        padding: 2.5px 0px;
                        font-size: 0.6rem;
                    }
                    .pollen-cv-template--single-section--skill-list-single-stars {
                        color: $prim-color;
                    }
                }
                &.pollen-cv-template--single-section--keyassets-list {
                    flex-direction: row;
                    flex-wrap: wrap;
                    justify-content: center;
                    .pollen-cv-template--single-section--skill-list-single {
                        width: auto;
                        padding: 5px 10px;
                    }
                }
            }

            .pollen-cv-template--single-section--language-list {
                display: flex;
                justify-content: space-around;
                flex-flow: row wrap;
                padding: 0px 5px;

                .pollen-cv-template--single-section--language-list-single {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-content: center;
                    align-items: center;
                    margin: 5px 0px;
                    @media screen and (max-width: 600px) {
                        font-size: 0.6rem;
                        margin: 2.5px 0px;
                    }
                    .pollen-cv-template--single-section--language-list-single-content {
                        text-overflow: ellipsis;
                        overflow: hidden;
                        white-space: nowrap;
                    }
                    .progress-circle-container {
                        display: flex;
                    }
                    .progress-circle {
                        position: relative;
                        display: inline-block;
                        width: 60px;
                        height: 60px;
                        border-radius: 50%;
                        background-color: rgba(85, 86, 88, 1);
                        @media screen and (max-width: 600px) {
                            width: 30px;
                            height: 30px;
                        }
                    }
                    .progress-circle:after {
                        content: "";
                        display: inline-block;
                        width: 100%;
                        height: 100%;
                        border-radius: 50%;
                        -webkit-animation: colorload 2s;
                        animation: colorload 2s;
                    }
                    .progress-circle span {
                        font-size: 1rem;
                        color: white;
                        position: absolute;
                        background-color: rgba(85, 86, 88, 1) !important;
                        left: 50%;
                        top: 50%;
                        display: block;
                        width: 45px;
                        height: 45px;
                        line-height: 45px;
                        margin-left: -22.5px;
                        margin-top: -22.5px;
                        text-align: center;
                        border-radius: 50%;
                        font-size: 12px;
                        z-index: 1;
                        @media screen and (max-width: 600px) {
                            font-size: 0.5rem;
                            width: 22.5px;
                            height: 22.5px;
                            line-height: 22.5px;
                            font-size: 6px;
                            margin-left: -11px;
                            margin-top: -11px;
                        }
                    }
                    .progress-circle span:after {
                        content: "%";
                        font-weight: 600;
                        font-size: 12px;
                        color: white;
                        @media screen and (max-width: 600px) {
                            font-weight: 300;
                            font-size: 6px;
                        }
                    }
                    .progress-circle.progress-25:after {
                        background-image: linear-gradient(90deg, rgba(85, 86, 88, 1) 50%, transparent 50%, transparent),
                            linear-gradient(180deg, $color1 50%, rgba(85, 86, 88, 1) 50%, rgba(85, 86, 88, 1));
                    }
                    .progress-circle.progress-50:after {
                        background-image: linear-gradient(-90deg, $color1 50%, transparent 50%, transparent),
                            linear-gradient(270deg, $color1 50%, rgba(85, 86, 88, 1) 50%, rgba(85, 86, 88, 1));
                    }
                    .progress-circle.progress-75:after {
                        background-image: linear-gradient(0deg, $color1 50%, transparent 50%, transparent),
                            linear-gradient(270deg, $color1 50%, rgba(85, 86, 88, 1) 50%, rgba(85, 86, 88, 1));
                    }
                    .progress-circle.progress-100:after {
                        background-image: linear-gradient(90deg, $color1 50%, transparent 50%, transparent),
                            linear-gradient(270deg, $color1 50%, rgba(85, 86, 88, 1) 50%, rgba(85, 86, 88, 1));
                    }
                }
            }
        }

        .main-content {
            overflow: hidden;
            flex: 4;
            padding: 40px 0px 0px 0px;
            @media screen and (max-width: 600px) {
                padding: 40px 0px 0px 0px;
            }

            .pollen-cv-template--single-section--header {
                h3 {
                    color: $prim-color;
                }
            }

            .pollen-cv-template--single-section--header-underline {
                border-top: 1px solid $prim-color;
            }

            .main-content--single-section {
                &:not(:last-of-type) {
                    margin-bottom: 30px;
                }
                .main-content--single-section-heading {
                    color: $prim-color;
                    border-bottom: 5px solid $prim-color;
                    padding-bottom: 15px;
                }
                .main-content--single-section-content {
                    &.job-list {
                        .main-content--single-section-content-single-job {
                            margin-top: 20px;

                            .main-content--single-section-content-single-job-company {
                                color: $prim-color;
                                font-weight: bold;
                                @media screen and (max-width: 600px) {
                                    font-size: 0.8rem;
                                }
                            }
                            .main-content--single-section-content-single-job-title {
                                color: $prim-color;
                                font-weight: bold;
                                font-size: 20px;
                                @media screen and (max-width: 600px) {
                                    font-size: 0.8rem;
                                }
                            }
                        }
                    }

                    &.education-list {
                        .main-content--single-section-content-single-education {
                            margin-top: 10px;

                            .main-content--single-section-content-single-education-company {
                                color: $prim-color;
                                font-weight: bold;
                            }
                            .main-content--single-section-content-single-education-title {
                                color: $prim-color;
                                font-weight: bold;
                                font-size: 20px;
                            }
                        }
                    }
                }
            }
        }
    }
}
