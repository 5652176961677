#orange-breeze-template-cv {
    $prim-color: #f0ad20;
    // padding: 30px;
    width: 100%;
    min-width: 100%;
    color: #54545e;
    padding: 0px 50px;
    @media screen and (max-width: 600px) {
        padding: 0px 25px;
    }

    .orange-breeze-cv-template--header {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        overflow: hidden;
        padding: 20px 0px;
        @media screen and (max-width: 600px) {
            padding: 10px 0px;
        }
        justify-content: space-between;

        .orange-breeze-cv-template--header--name {
            display: flex;
            flex-direction: column;
            padding: 0px 0px;
            h1 {
                margin: 0;
                // &:nth-of-type(2) {
                //     text-align: center;
                // }
                font-size: 4rem;
                @media screen and (max-width: 600px) {
                    font-size: 2rem;
                }
                text-transform: uppercase;
            }
            div {
                margin-top: 10px;
                span:first-of-type {
                    color: #c87e2a;
                }
            }
        }

        .orange-breeze-cv-template--header--contact {
            display: flex;
            flex-direction: column;
            .orange-breeze-cv-template--single-contact {
                display: flex;
                flex-direction: row;
                .orange-breeze-cv-template--single-section--icon-list-single-content {
                    display: flex;
                    flex-direction: column;
                    flex: 1;
                    .obcv-c-h,
                    .obcv-c-v {
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        align-self: flex-end;
                        &:not(.obcv-c-v) {
                            font-weight: bold;
                        }
                        &:not(.obcv-c-h) {
                            font-size: 13px;
                            @media screen and (max-width: 600px) {
                                font-size: 6.5px;
                            }
                        }
                    }
                }
                .orange-breeze-cv-template--single-contact--icon {
                    margin: 0px 0px 0px 20px;
                    @media screen and (max-width: 600px) {
                        margin: 0px 0px 0px 10px;
                    }
                }
            }
        }

        // .orange-breeze-cv-template--header--img {
        //     // margin-right: 110px;
        //     overflow: hidden;
        //     position: relative;
        //     padding: 30px 40px;
        //     height: 100%;
        // }

        // .hexagon {
        //     overflow: hidden;
        //     visibility: hidden;
        //     -webkit-transform: rotate(120deg);
        //     -moz-transform: rotate(120deg);
        //     -ms-transform: rotate(120deg);
        //     -o-transform: rotate(120deg);
        //     transform: rotate(120deg);
        //     cursor: pointer;
        // }
        // .hexagon-in1 {
        //     overflow: hidden;
        //     width: 100%;
        //     height: 100%;
        //     -webkit-transform: rotate(-60deg);
        //     -moz-transform: rotate(-60deg);
        //     -ms-transform: rotate(-60deg);
        //     -o-transform: rotate(-60deg);
        //     transform: rotate(-60deg);
        // }
        // .hexagon-in2 {
        //     width: 100%;
        //     height: 100%;
        //     background-repeat: no-repeat;
        //     background-position: 50%;
        //     visibility: visible;
        //     transform: rotate(-60deg);
        // }

        // .hexagon2 {
        //     width: 150px;
        //     height: 300px;
        //     margin: -80px 0 0 20px;
        // }
    }

    .obcv-profession {
        background-color: $prim-color;
        color: rgba(255, 255, 255, 0.88);
        padding: 2px 10px 2px 50px;
        font-size: 1.3rem;
        @media screen and (max-width: 600px) {
            font-size: 1rem;
        }
    }

    .obcv-hero {
        display: flex;
        margin-bottom: 50px;
        @media screen and (max-width: 600px) {
            margin-bottom: 25px;
        }
        .obcv-hero-img {
            background-color: #54545e;
        }
        .obcv-hero-content {
            padding: 50px 0px 25px 0px;
            text-transform: uppercase;
            border-bottom: 3px solid rgba(0, 0, 0, 0.5);
            @media screen and (max-width: 600px) {
                padding: 25px 0px 12.5px 0px;
            }
            .obcv-hero-content-title {
                font-weight: normal;
                margin-bottom: 5px;
                font-size: 40px;
                @media screen and (max-width: 600px) {
                    font-size: 30px;
                }
            }
            .obcv-hero-content-title-border {
                display: block;
                height: 3px;
                background: $prim-color;
                width: 100px;
                margin: 0px 0px 10px 5px;
            }
        }
    }

    .obcv-t-l {
        .obcv-t-l-c {
            text-transform: uppercase;
            font-size: 1.1rem;
            @media screen and (max-width: 600px) {
                font-size: 0.6rem;
            }
        }
        .obcv-t-l-d {
            font-size: 1.1rem;
            @media screen and (max-width: 600px) {
                font-size: 0.6rem;
            }
        }
    }
    .obcv-t-r {
        .obcv-t-r-t {
            text-transform: uppercase;
            font-size: 1.1rem;
            font-weight: normal;
            @media screen and (max-width: 600px) {
                font-size: 1rem;
            }
        }
        .obcv-t-r-c {
            font-size: 0.9rem;
            @media screen and (max-width: 600px) {
                font-size: 0.7rem;
            }
        }
    }

    .obcv-t-content-wrapper-r {
        flex: 2;
    }

    .obcv-t {
        .MuiTimelineDot-defaultGrey {
            background-color: #54545e;
            padding: 6px;
            margin-bottom: 0px;
            margin-top: 0px;
            @media screen and (max-width: 600px) {
                padding: 3px;
            }
        }
        .MuiTimelineConnector-root {
            background-color: #54545e;
            width: 3px;
        }
    }

    .content-wrapper {
        width: 100%;
        display: flex;
        align-content: center;
        justify-content: center;
        overflow: hidden;

        .orange-breeze-cv-template--single-section {
            &:not(:last-of-type) {
                margin-bottom: 20px;
            }
            .orange-breeze-cv-template--single-section--header {
                display: flex;
                flex-direction: row;
                align-content: center;
                margin-bottom: 10px;
                border-bottom: 3px solid rgba(0, 0, 0, 0.5);
                justify-content: space-between;
                .orange-breeze-cv-template--single-section--heading {
                    align-self: center;
                    border-radius: 3px;
                    font-size: 1.8rem;
                    text-transform: uppercase;
                    margin: 3px 0px;
                    @media screen and (max-width: 600px) {
                        font-size: 0.8rem;
                    }
                }

                .orange-breeze-cv-template--single-section--header-icon {
                    display: flex;
                    align-content: center;
                    justify-content: center;
                    align-items: center;
                }
            }
        }

        .left-sidebar {
            flex: 4;
            overflow: hidden;
            .orange-breeze-cv-template--single-section--icon-list {
                display: flex;
                flex-direction: column;
                .orange-breeze-cv-template--single-section--icon-list-single {
                    display: flex;
                    justify-content: flex-start;
                    padding: 5px 0px;
                    overflow: hidden;
                    .orange-breeze-cv-template--single-section--icon-list-single-icon {
                        background-color: #54545e;
                        border-radius: 5px;
                        color: white;
                        padding: 5px;
                        display: flex;
                        margin-right: 20px;
                        align-self: center;
                        opacity: 0.95;
                        @media screen and (max-width: 600px) {
                            margin-right: 10px;
                        }
                    }
                    .orange-breeze-cv-template--single-section--icon-list-single-content {
                        font-size: 16px;
                        @media screen and (max-width: 600px) {
                            font-size: 12px;
                        }
                    }
                }
            }

            .orange-breeze-cv-template--single-section--skill-list,
            .orange-breeze-cv-template--single-section--keyassets-list {
                display: flex;
                overflow: hidden;
                flex-direction: column;

                .orange-breeze-cv-template--single-section--skill-list-single {
                    justify-content: space-between;
                    display: flex;
                    flex-direction: row;
                    width: 100%;
                    padding: 5px 0px;
                    align-items: center;

                    .orange-breeze-cv-template--single-section--skill-list-single-stars {
                        min-width: 130px;
                        svg {
                            margin: 0px 3px;
                            @media screen and (max-width: 600px) {
                                margin: 0px 1px;
                            }
                        }
                    }
                    .orange-breeze-cv-template--single-section--skill-list-single-skill {
                        font-size: 16px;
                        @media screen and (max-width: 600px) {
                            font-size: 12px;
                        }
                    }
                }
                &.orange-breeze-cv-template--single-section--keyassets-list {
                    flex-direction: row;
                    flex-wrap: wrap;
                    justify-content: center;
                    .orange-breeze-cv-template--single-section--skill-list-single {
                        width: auto;
                        padding: 5px 10px;
                    }
                }
            }

            .orange-breeze-cv-template--single-section--language-list {
                display: flex;
                justify-content: space-around;
                flex-flow: row wrap;
                // padding: 0px 5px;

                .orange-breeze-cv-template--single-section--language-list-single {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-content: center;
                    align-items: center;
                    margin: 5px 0px;
                    @media screen and (max-width: 600px) {
                        margin: 2.5px 0px;
                    }
                    .orange-breeze-cv-template--single-section--language-list-single-content {
                        text-overflow: ellipsis;
                        overflow: hidden;
                        white-space: nowrap;
                        font-size: 18px;
                        @media screen and (max-width: 600px) {
                            font-size: 9px;
                        }
                    }
                    .progress-circle-container {
                        display: flex;
                    }
                    .progress-circle {
                        position: relative;
                        display: inline-block;
                        width: 60px;
                        height: 60px;
                        border-radius: 50%;
                        background-color: rgba(85, 86, 88, 1);
                        @media screen and (max-width: 600px) {
                            width: 30px;
                            height: 30px;
                        }
                    }
                    .progress-circle:after {
                        content: "";
                        display: inline-block;
                        width: 100%;
                        height: 100%;
                        border-radius: 50%;
                        -webkit-animation: colorload 2s;
                        animation: colorload 2s;
                    }
                    .progress-circle span {
                        font-size: 1rem;
                        color: white;
                        position: absolute;
                        background-color: rgba(85, 86, 88, 1) !important;
                        left: 50%;
                        top: 50%;
                        display: block;
                        width: 45px;
                        height: 45px;
                        line-height: 45px;
                        margin-left: -22.5px;
                        margin-top: -22.5px;
                        text-align: center;
                        border-radius: 50%;
                        font-size: 12px;
                        z-index: 1;
                        @media screen and (max-width: 600px) {
                            font-size: 0.5rem;
                            width: 22.5px;
                            height: 22.5px;
                            line-height: 22.5px;
                            font-size: 6px;
                            margin-left: -11px;
                            margin-top: -11px;
                        }
                    }
                    .progress-circle span:after {
                        content: "%";
                        font-weight: 600;
                        font-size: 12px;
                        color: white;
                        @media screen and (max-width: 600px) {
                            font-weight: 300;
                            font-size: 6px;
                        }
                    }
                    .progress-circle.progress-25:after {
                        background-image: linear-gradient(90deg, rgba(85, 86, 88, 1) 50%, transparent 50%, transparent),
                            linear-gradient(180deg, $prim-color 50%, rgba(85, 86, 88, 1) 50%, rgba(85, 86, 88, 1));
                    }
                    .progress-circle.progress-50:after {
                        background-image: linear-gradient(-90deg, $prim-color 50%, transparent 50%, transparent),
                            linear-gradient(270deg, $prim-color 50%, rgba(85, 86, 88, 1) 50%, rgba(85, 86, 88, 1));
                    }
                    .progress-circle.progress-75:after {
                        background-image: linear-gradient(0deg, $prim-color 50%, transparent 50%, transparent),
                            linear-gradient(270deg, $prim-color 50%, rgba(85, 86, 88, 1) 50%, rgba(85, 86, 88, 1));
                    }
                    .progress-circle.progress-100:after {
                        background-image: linear-gradient(90deg, $prim-color 50%, transparent 50%, transparent),
                            linear-gradient(270deg, $prim-color 50%, rgba(85, 86, 88, 1) 50%, rgba(85, 86, 88, 1));
                    }
                }
            }
        }

        .main-content {
            overflow: hidden;
            flex: 9;
            padding: 0px 0px 10px 30px;

            .main-content--single-section {
                &:not(:last-of-type) {
                    margin-bottom: 30px;
                }
                .main-content--single-section-heading {
                    color: $prim-color;
                    border-bottom: 5px solid $prim-color;
                    padding-bottom: 15px;
                }
                .main-content--single-section-content {
                    &.job-list {
                        .main-content--single-section-content-single-job {
                            margin-top: 20px;

                            .main-content--single-section-content-single-job-company {
                                color: $prim-color;
                                font-weight: bold;
                            }
                            .main-content--single-section-content-single-job-title {
                                color: $prim-color;
                                font-weight: bold;
                                font-size: 20px;
                            }
                        }
                    }

                    &.education-list {
                        .main-content--single-section-content-single-education {
                            margin-top: 10px;

                            .main-content--single-section-content-single-education-company {
                                color: $prim-color;
                                font-weight: bold;
                            }
                            .main-content--single-section-content-single-education-title {
                                color: $prim-color;
                                font-weight: bold;
                                font-size: 20px;
                            }
                        }
                    }
                }
            }
        }
    }
}
