@import "../../vars";
footer {
  height: 302px;
  overflow: hidden;
  background-color: #f5f5f5 !important;
  .footer--top-section {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    .footer--top-section--wrapper {
      height: 100px;
      align-content: center;
      align-items: center;
      justify-content: center;
    }

    .footer--top-section--menu-wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: space-around;
      height: 100%;
      .footer--top-section--menu {
        margin: 0px;
        padding: 0px;
        list-style: none;
        display: flex;
        li {
          margin: 0px 10px;
          a {
            color: $primary-color;
            text-decoration: none;
            &:hover {
              color: $primary-bg;
              text-decoration: none;
            }
          }
        }
      }

      .footer--top-section--social--menu {
        margin: 0px;
        padding: 0px;
        list-style: none;
        display: flex;
        li {
          margin: 0px 10px;
          a {
            color: $primary-color;
            text-decoration: none;
            &:hover {
              color: $primary-bg;
              text-decoration: none;
            }
          }
        }
      }
    }

    p {
      margin: 0 0px;
    }
  }

  .footer--bottom-section {
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    .footer--bottom-section--wrapper {
      // height: 80px;
      display: flex;
      align-content: center;
      align-items: center;
      justify-content: space-between;
    }
    .footer--bottom-section--menu {
      margin: 0px;
      padding: 0px;
      list-style: none;
      display: flex;
      li {
        margin: 0px 10px;
        a {
          color: $primary-color;
          text-decoration: none;
          &:hover {
            color: $primary-bg;
            text-decoration: none;
          }
        }
      }
    }
    p {
      margin: 0 0px;
    }

    .footer--bottom-section--contact-holder {
      display: flex;
      flex: 1;
      a {
        color: $primary-color;
        margin: 0px 15px;
        text-decoration: none;
        &:hover {
          color: $primary-bg;
        }
      }
    }

    .credit-link {
      margin: 20px 15px 0px 15px !important;
    }

    .footer--bottom-section--wrapper--logo {
      color: $primary-bg;
      text-decoration: none;
      &:hover {
        color: $primary-bg;
      }
      img {
        width: 50px;
        margin: 30px;
      }
      h3 {
        color: $primary-bg;
      }
    }
  }

  .logo-bottom {
    width: 100px;
  }
}

@media only screen and (max-width: 768px) {
  footer {
    .footer--top-section {
      .footer--top-section--wrapper {
        flex-direction: column-reverse;
        justify-content: center !important;
        padding: 10px 0px;
        .footer--top-section--menu-wrapper {
          align-items: center;
        }
      }
    }

    .footer--bottom-section {
      .footer--bottom-section--wrapper {
        flex-direction: column;
      }
      .footer--bottom-section--contact-holder {
        flex-direction: column;
        a {
          margin: 2px 0px;
        }
      }

      .footer--bottom-section--wrapper--logo {
        margin-top: 30px;
      }
    }
  }
}
