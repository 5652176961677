#nectar-template-cv {
    $prim-color: #424242;
    // padding: 30px;
    width: 100%;
    min-width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;

    .nectar-cv-template--header {
        // height: 400px;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        overflow: hidden;
        justify-content: space-between;

        .kcvt-h-l {
            flex: 4;
            background-color: #515151;
            -webkit-box-shadow: 4px 0px 5px 0px rgba(145, 145, 145, 1);
            -moz-box-shadow: 4px 0px 5px 0px rgba(145, 145, 145, 1);
            box-shadow: 4px 0px 5px 0px rgba(145, 145, 145, 1);
            color: white;
            display: flex;
            align-self: stretch;
        }
    }

    .nectar-cv-template--header-sub {
        width: 100%;
        height: 50px;
        background-color: $prim-color;
        display: flex;
        justify-content: space-around;
        .nectar-sh-s {
            display: flex;
            align-items: center;
            .nectar-sh-s-i {
                background-color: #3e3d3d;
                border-radius: 50px;
                color: $prim-color;
                padding: 5px;
                display: flex;
                margin-right: 10px;
                align-self: center;
            }

            .nectar-sh-s-c {
                color: #3e3d3d;
                a,
                a:hover {
                    color: #3e3d3d;
                    text-decoration: none;
                }
            }
        }
    }

    .top-left-ribbon {
        position: absolute;
        top: 0;
        left: 0;
        width: 550px;
        z-index: 1;
    }

    .bottom-right-ribbon {
        position: absolute;
        bottom: 0;
        right: 0;
        height: 200px;
        z-index: 1;
    }

    .bottom-left-ribbon {
        position: absolute;
        bottom: 0;
        left: 0;
        height: 100px;
        z-index: 1;
    }

    .nectar-img-container {
        width: 345px;
        height: 345px;
        background-color: #62615f;
        position: absolute;
        z-index: 2;
        border: 5px solid $prim-color;
        transform: rotate(45deg);
        top: 60px;
        left: 60px;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-content: center;
        align-items: center;
        img {
            // position: absolute;
            // top: 0;
            // left: 0;
            // right: 0;
            // bottom: 0;
            height: 100%;
            width: 100%;
            transform: rotate(315deg);
            scale: 143%;
        }
    }

    .content-wrapper {
        width: 100%;
        display: flex;
        align-content: center;
        justify-content: center;
        overflow: hidden;
        flex: 1;

        .left-sidebar {
            background-repeat: repeat-y;
            background-size: contain;
            align-self: stretch;
            flex: 4;
            overflow: hidden;
            background-color: #515151;
            position: relative;
            color: white;
            display: flex;
            flex-direction: column;
            padding-top: 90px;

            .nectar-cv-template--single-section {
                &:last-of-type {
                    margin-bottom: 20px;
                }
                .nectar-cv-template--single-section--content {
                    margin-top: 10px;
                }
                .nectar-cv-template--single-section--header {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-content: center;
                    margin-top: 15px;
                    .nectar-cv-template--single-section--heading {
                        align-self: center;
                        border-radius: 3px;
                        color: $prim-color;
                        text-transform: uppercase;
                        font-size: 2rem;
                        padding: 10px 0px;
                        @media screen and (max-width: 600px) {
                            font-size: 1em;
                            padding: 5px 0px;
                        }
                    }
                }
            }

            .nectar-cv-template--single-section--icon-list {
                display: flex;
                flex-direction: column;
                .nectar-cv-template--single-section--icon-list-single {
                    display: flex;
                    justify-content: flex-start;
                    padding: 5px 0px;
                    overflow: hidden;
                    @media screen and (max-width: 600px) {
                        font-size: 0.6rem;
                        padding: 2.5px 0px;
                    }
                    a,
                    a:hover {
                        color: $prim-color;
                        text-decoration: none;
                        @media screen and (max-width: 600px) {
                            font-size: 0.6rem;
                        }
                    }
                    .nectar-cv-template--single-section--icon-list-single-icon {
                        background-color: transparent;
                        color: white;
                        padding: 5px 30px 5px 10px;
                        display: flex;
                        margin-right: 5px;
                        align-self: center;
                        background-repeat: no-repeat;
                        background-size: cover;
                        background-position: center;
                        @media screen and (max-width: 600px) {
                            padding: 2.5px 15px 2.5px 5px;
                        }
                    }

                    .nectar-cv-template--single-section--icon-list-single-content {
                        color: $prim-color;
                    }
                }
            }

            .nectar-cv-template--single-section--skill-list,
            .nectar-cv-template--single-section--keyassets-list {
                display: flex;
                overflow: hidden;
                flex-direction: column;
                padding: 5px 50px;
                @media screen and (max-width: 600px) {
                    padding: 2.5px 25px;
                }

                &.l-u {
                    .nectar-cv-template--single-section--skill-list-single-skill {
                        color: $prim-color;
                        &::before {
                            content: "•";
                            float: left;
                            display: inline-block;
                            font-weight: bold;
                            font-size: 30px;
                            line-height: 1;
                            margin: -5px 10px 0px 0px;
                            @media screen and (max-width: 600px) {
                                font-size: 1rem;
                                margin: -2.5px 5px 0px 0px;
                            }
                        }
                    }
                }

                .nectar-cv-template--single-section--skill-list-single {
                    justify-content: space-between;
                    display: flex;
                    flex-direction: row;
                    width: 100%;
                    // padding: 5px 50px;
                    @media screen and (max-width: 600px) {
                        font-size: 0.6rem;
                    }

                    .nectar-cv-template--single-section--skill-list-single-stars {
                        color: white;
                    }
                }
                &.nectar-cv-template--single-section--keyassets-list {
                    flex-direction: row;
                    flex-wrap: wrap;
                    justify-content: flex-start;
                    .nectar-cv-template--single-section--skill-list-single {
                        width: auto;
                        padding: 5px 10px;
                    }
                }
            }

            .nectar-cv-template--single-section--language-list {
                display: flex;
                justify-content: space-around;
                flex-flow: row wrap;
                padding: 0px 5px;
                @media screen and (max-width: 600px) {
                    padding: 0px 2.5px;
                }

                .nectar-cv-template--single-section--language-list-single {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-content: center;
                    align-items: center;
                    margin: 5px 0px;
                    .nectar-cv-template--single-section--language-list-single-content {
                        text-overflow: ellipsis;
                        overflow: hidden;
                        white-space: nowrap;
                        color: $prim-color;
                        @media screen and (max-width: 600px) {
                            font-size: 0.6rem;
                        }
                    }
                    .progress-circle-container {
                        display: flex;
                    }
                    .progress-circle {
                        position: relative;
                        display: inline-block;
                        width: 60px;
                        height: 60px;
                        border-radius: 50%;
                        background-color: rgba(85, 86, 88, 1);
                        border: 1px solid rgba(255, 255, 255, 0.2);
                    }
                }
            }
        }

        .main-content {
            overflow: hidden;
            flex: 8;
            background-repeat: repeat-y;
            background-size: contain;

            overflow: hidden;

            .main-content-header {
                height: 135px;
                width: 100%;
                margin-top: 100px;
                margin-left: -3px;
                background-repeat: no-repeat;
                background-size: 100%;
                background-position: center;
                display: flex;
                @media screen and (max-width: 600px) {
                    height: 66px;
                }

                .nectar-cv-template--header--name {
                    align-self: center;
                    display: flex;
                    flex: 1;
                    flex-direction: column;
                    // align-items: center;
                    padding: 0px 100px;
                    @media screen and (max-width: 600px) {
                        padding: 0px 50px;
                    }
                    h1 {
                        text-transform: uppercase;
                        margin: 0;
                        font-weight: lighter;
                        color: white;
                        line-height: 1;
                        font-size: 3.2rem;
                        @media screen and (max-width: 600px) {
                            font-size: 1.6rem;
                        }
                    }
                    span {
                        color: white;
                        text-transform: uppercase;
                        line-height: 2;
                        font-weight: lighter;
                        @media screen and (max-width: 600px) {
                            font-size: 0.8rem;
                        }
                    }
                }
            }

            .main-content-wrapper {
                padding: 10px 30px 10px 50px;
                @media screen and (max-width: 600px) {
                    padding: 5px 15px 5px 25px;
                }
            }

            .main-content--single-section {
                margin-top: 50px;
                @media screen and (max-width: 600px) {
                    margin-top: 25px;
                }
                &:last-of-type {
                    margin-bottom: 100px;
                    @media screen and (max-width: 600px) {
                        margin-bottom: 50px;
                    }
                }
                .main-content--single-section-heading {
                    font-size: 1.8rem;
                    display: flex;
                    align-content: center;
                    align-items: center;
                    margin-bottom: 20px;

                    color: $prim-color;
                    text-transform: uppercase;
                    @media screen and (max-width: 600px) {
                        margin-bottom: 10px;
                        font-size: 1.2rem;
                    }
                }
                .main-content--single-section-content {
                    @media screen and (max-width: 600px) {
                        font-size: 0.8rem;
                    }
                    &.job-list {
                        .main-content--single-section-content-single-job {
                            margin-top: 20px;
                            @media screen and (max-width: 600px) {
                                font-size: 0.8rem;
                            }

                            .main-content--single-section-content-single-job-description {
                                padding-left: 25px;
                            }

                            .main-content--single-section-content-single-job-company {
                                color: #515151;
                                font-weight: bold;
                                &::before {
                                    content: " ";
                                    float: left;
                                    display: inline-block;
                                    font-weight: bold;
                                    height: 30px;
                                    width: 15px;
                                    line-height: 1;
                                    margin: -5px 10px 0px 0px;
                                    @media screen and (max-width: 600px) {
                                        margin: -2.5px -15px 0px 0px;
                                    }
                                }
                            }
                            .main-content--single-section-content-single-job-title {
                                color: #515151;
                                font-weight: bold;
                                @media screen and (max-width: 600px) {
                                    font-size: 1rem;
                                }
                                font-size: 20px;
                                &::before {
                                    content: "•";
                                    float: left;
                                    display: inline-block;
                                    font-weight: bold;
                                    font-size: 30px;
                                    line-height: 1;
                                    margin: -5px 10px 0px 0px;
                                }
                            }
                        }
                    }

                    &.education-list {
                        .main-content--single-section-content-single-education {
                            margin-top: 10px;
                            @media screen and (max-width: 600px) {
                                font-size: 0.8rem;
                            }

                            .main-content--single-section-content-single-education-description {
                                padding-left: 25px;
                            }

                            .main-content--single-section-content-single-education-company {
                                color: #515151;
                                font-weight: bold;
                                &::before {
                                    content: "";
                                    float: left;
                                    display: inline-block;
                                    font-weight: bold;
                                    width: 15px;
                                    height: 30px;
                                    line-height: 1;
                                    margin: -5px 10px 0px 0px;
                                    @media screen and (max-width: 600px) {
                                        margin: -2.5px -15px 0px 0px;
                                    }
                                }
                            }
                            .main-content--single-section-content-single-education-title {
                                color: #515151;
                                font-weight: bold;
                                font-size: 20px;
                                @media screen and (max-width: 600px) {
                                    font-size: 1rem;
                                }
                                &::before {
                                    content: "•";
                                    float: left;
                                    display: inline-block;
                                    font-weight: bold;
                                    font-size: 30px;
                                    line-height: 1;
                                    margin: -5px 10px 0px 0px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
