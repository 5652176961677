#imperial-red-template-cv {
    $prim-color: #b64a37;
    // padding: 30px;
    width: 100%;
    min-width: 100%;

    .imperial-red-cv-template--header {
        height: 200px;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        overflow: hidden;

        justify-content: space-between;

        .imperial-red-cv-template--header--name {
            display: flex;
            flex-direction: column;
            padding: 40px;
            h1 {
                margin: 0;
                font-size: 40px;
                @media screen and (max-width: 600px) {
                    font-size: 30px;
                }
                // &:nth-of-type(2) {
                //     text-align: center;
                // }
            }
            div {
                margin-top: 10px;
                span:first-of-type {
                    color: #c87e2a;
                }
            }
            @media screen and (max-width: 600px) {
                padding: 20px;
            }
        }

        .imperial-red-cv-template--header--img {
            // margin-right: 110px;
            overflow: hidden;
            position: relative;
            padding: 30px 40px;
            height: 100%;
            @media screen and (max-width: 600px) {
                padding: 15px 20px;
            }
        }

        .hexagon {
            overflow: hidden;
            visibility: hidden;
            -webkit-transform: rotate(120deg);
            -moz-transform: rotate(120deg);
            -ms-transform: rotate(120deg);
            -o-transform: rotate(120deg);
            transform: rotate(120deg);
            cursor: pointer;
        }
        .hexagon-in1 {
            overflow: hidden;
            width: 100%;
            height: 100%;
            -webkit-transform: rotate(-60deg);
            -moz-transform: rotate(-60deg);
            -ms-transform: rotate(-60deg);
            -o-transform: rotate(-60deg);
            transform: rotate(-60deg);
        }
        .hexagon-in2 {
            width: 100%;
            height: 100%;
            background-repeat: no-repeat;
            background-position: 50%;
            visibility: visible;
            transform: rotate(-60deg);
        }

        .hexagon2 {
            width: 150px;
            height: 300px;
            margin: -80px 0 0 20px;
        }
    }

    .imperial-red-cv-template--header-sub {
        width: 100%;
        background-color: rgba(85, 86, 88, 1);
        .imperial-red-cv-template--header-sub-heading {
            display: flex;
            span:not(.corner) {
                background-color: rgba(158, 46, 54, 1);
                color: white;
                display: inline-block;
                padding: 5px 80px;
                font-size: 22px;
                text-transform: uppercase;
                @media screen and (max-width: 600px) {
                    padding: 2.5px 40px;
                    font-size: 11px;
                }
            }
            span.corner {
                width: 0;
                height: 0;
                border-top: 44px solid rgba(158, 46, 54, 1);
                border-right: 44px solid transparent;
                @media screen and (max-width: 600px) {
                    border-top: 22px solid rgba(158, 46, 54, 1);
                    border-right: 22px solid transparent;
                }
            }
        }
        .imperial-red-cv-template--header-sub-content {
            color: white;
            padding: 25px 80px;
            @media screen and (max-width: 600px) {
                padding: 12.5px 40px;
            }
        }
    }

    .content-wrapper {
        width: 100%;
        display: flex;
        align-content: center;
        justify-content: center;
        overflow: hidden;

        .divider_ {
            min-height: 100%;
            background-color: $prim-color;
            width: 5px;
            background-color: $prim-color;
            margin: 33px 33px 33px 40px;
            @media screen and (max-width: 600px) {
                width: 2.5px;
                margin: 16.5px 16.5px 16.5px 20px;
            }
        }

        .left-sidebar {
            flex: 4;
            overflow: hidden;
            padding: 0px 0px 0px 20px;
            @media screen and (max-width: 600px) {
                padding: 0px 0px 0px 10px;
            }
            .imperial-red-cv-template--single-section {
                .imperial-red-cv-template--single-section--header {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-content: center;
                    margin-bottom: 10px;
                    .imperial-red-cv-template--single-section--heading {
                        border-bottom: 5px solid $prim-color;
                        align-self: center;
                        border-radius: 3px;
                        color: $prim-color;
                        font-size: 30pt;
                        padding: 10px 0px;
                        @media screen and (max-width: 600px) {
                            font-size: 15pt;
                            padding: 5px 0px;
                        }
                    }

                    .imperial-red-cv-template--single-section--header-underline {
                        height: 0px;
                        border-top: 2px solid rgba(0, 0, 0, 0.2);
                        margin-top: -11.5px;
                        margin-bottom: 10px;
                    }
                }
            }

            .imperial-red-cv-template--single-section--icon-list {
                display: flex;
                flex-direction: column;
                .imperial-red-cv-template--single-section--icon-list-single {
                    display: flex;
                    justify-content: flex-start;
                    padding: 5px 20px;
                    overflow: hidden;
                    @media screen and (max-width: 600px) {
                        padding: 2.5px 5px;
                    }
                    .imperial-red-cv-template--single-section--icon-list-single-icon {
                        background-color: #555658;
                        border-radius: 50px;
                        color: white;
                        padding: 5px;
                        display: flex;
                        margin-right: 20px;
                        align-self: center;
                        @media screen and (max-width: 600px) {
                            padding: 2.5px;
                            margin-right: 10px;
                        }
                    }
                    .imperial-red-cv-template--single-section--icon-list-single-content {
                        font-size: 18px;
                        @media screen and (max-width: 600px) {
                            font-size: 9px;
                        }
                    }
                }
            }

            .imperial-red-cv-template--single-section--skill-list,
            .imperial-red-cv-template--single-section--keyassets-list {
                display: flex;
                overflow: hidden;
                flex-direction: column;

                .imperial-red-cv-template--single-section--skill-list-single {
                    justify-content: space-between;
                    display: flex;
                    flex-direction: row;
                    width: 100%;
                    padding: 5px 20px;
                    @media screen and (max-width: 600px) {
                        padding: 2.5px 5px;
                    }
                    .imperial-red-cv-template--single-section--skill-list-single-skill{
                        font-size: 18px;
                        @media screen and (max-width: 600px) {
                            font-size: 9px;
                        }
                    }

                    .imperial-red-cv-template--single-section--skill-list-single-stars {
                        color: $prim-color;
                    }
                }
                &.imperial-red-cv-template--single-section--keyassets-list {
                    flex-direction: row;
                    flex-wrap: wrap;
                    justify-content: center;
                    .imperial-red-cv-template--single-section--skill-list-single {
                        width: auto;
                        padding: 5px 10px;
                        @media screen and (max-width: 600px) {
                            padding: 2.5px 5px;
                        }
                    }
                }
            }

            .imperial-red-cv-template--single-section--language-list {
                display: flex;
                justify-content: space-around;
                flex-flow: row wrap;
                padding: 0px 5px;
                @media screen and (max-width: 600px) {
                    padding: 0px 2.5px;
                }

                .imperial-red-cv-template--single-section--language-list-single {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-content: center;
                    align-items: center;
                    margin: 5px 0px;
                    @media screen and (max-width: 600px) {
                        margin: 2.5px 0px;
                    }
                    .imperial-red-cv-template--single-section--language-list-single-content {
                        text-overflow: ellipsis;
                        overflow: hidden;
                        white-space: nowrap;
                        font-size: 18px;
                        @media screen and (max-width: 600px) {
                            font-size: 9px;
                        }
                    }
                    .progress-circle-container {
                        display: flex;
                    }
                    .progress-circle {
                        position: relative;
                        display: inline-block;
                        width: 60px;
                        height: 60px;
                        border-radius: 50%;
                        background-color: rgba(85, 86, 88, 1);
                        @media screen and (max-width: 600px) {
                            width: 30px;
                            height: 30px;
                        }
                    }
                    .progress-circle:after {
                        content: "";
                        display: inline-block;
                        width: 100%;
                        height: 100%;
                        border-radius: 50%;
                        -webkit-animation: colorload 2s;
                        animation: colorload 2s;
                    }
                    .progress-circle span {
                        font-size: 1rem;
                        color: white;
                        position: absolute;
                        background-color: rgba(85, 86, 88, 1) !important;
                        left: 50%;
                        top: 50%;
                        display: block;
                        width: 45px;
                        height: 45px;
                        line-height: 45px;
                        margin-left: -22.5px;
                        margin-top: -22.5px;
                        text-align: center;
                        border-radius: 50%;
                        font-size: 12px;
                        z-index: 1;
                        @media screen and (max-width: 600px) {
                            font-size: 0.5rem;
                            width: 22.5px;
                            height: 22.5px;
                            line-height: 22.5px;
                            font-size: 6px;
                            margin-left: -11px;
                            margin-top: -11px;
                        }
                    }
                    .progress-circle span:after {
                        content: "%";
                        font-weight: 600;
                        font-size: 12px;
                        color: white;
                        @media screen and (max-width: 600px) {
                            font-weight: 300;
                            font-size: 6px;
                        }
                    }
                    .progress-circle.progress-25:after {
                        background-image: linear-gradient(90deg, rgba(85, 86, 88, 1) 50%, transparent 50%, transparent),
                            linear-gradient(180deg, $prim-color 50%, rgba(85, 86, 88, 1) 50%, rgba(85, 86, 88, 1));
                    }
                    .progress-circle.progress-50:after {
                        background-image: linear-gradient(-90deg, $prim-color 50%, transparent 50%, transparent),
                            linear-gradient(270deg, $prim-color 50%, rgba(85, 86, 88, 1) 50%, rgba(85, 86, 88, 1));
                    }
                    .progress-circle.progress-75:after {
                        background-image: linear-gradient(0deg, $prim-color 50%, transparent 50%, transparent),
                            linear-gradient(270deg, $prim-color 50%, rgba(85, 86, 88, 1) 50%, rgba(85, 86, 88, 1));
                    }
                    .progress-circle.progress-100:after {
                        background-image: linear-gradient(90deg, $prim-color 50%, transparent 50%, transparent),
                            linear-gradient(270deg, $prim-color 50%, rgba(85, 86, 88, 1) 50%, rgba(85, 86, 88, 1));
                    }
                }
            }
        }

        .main-content {
            overflow: hidden;
            flex: 9;
            padding: 10px 30px 10px 10px;
            @media screen and (max-width: 600px) {
                padding: 5px 15px 5px 5px;
            }

            .main-content--single-section {
                &:not(:last-of-type) {
                    margin-bottom: 30px;
                }
                .main-content--single-section-heading {
                    color: $prim-color;
                    border-bottom: 5px solid $prim-color;
                    padding-bottom: 15px;
                    font-size: 40px;
                    @media screen and (max-width: 600px) {
                        font-size: 20px;
                    }
                }
                .main-content--single-section-content {
                    &.job-list {
                        .main-content--single-section-content-single-job {
                            margin-top: 20px;

                            .main-content--single-section-content-single-job-company {
                                color: $prim-color;
                                font-weight: bold;
                            }
                            .main-content--single-section-content-single-job-title {
                                color: $prim-color;
                                font-weight: bold;
                                font-size: 20px;
                                @media screen and (max-width: 600px) {
                                    font-size: 10px;
                                }
                            }
                        }
                    }

                    &.education-list {
                        .main-content--single-section-content-single-education {
                            margin-top: 10px;

                            .main-content--single-section-content-single-education-company {
                                color: $prim-color;
                                font-weight: bold;
                            }
                            .main-content--single-section-content-single-education-title {
                                color: $prim-color;
                                font-weight: bold;
                                font-size: 20px;
                                @media screen and (max-width: 600px) {
                                    font-size: 10px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
