#kino-template-cv {
    $prim-color: #f8ae45;
    // padding: 30px;
    width: 100%;
    min-width: 100%;
    display: flex;
    flex-direction: column;

    .kino-cv-template--header {
        // height: 400px;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        overflow: hidden;
        justify-content: space-between;

        .kcvt-h-l {
            flex: 4;
            background-color: #515151;
            -webkit-box-shadow: 4px 0px 5px 0px rgba(145, 145, 145, 1);
            -moz-box-shadow: 4px 0px 5px 0px rgba(145, 145, 145, 1);
            box-shadow: 4px 0px 5px 0px rgba(145, 145, 145, 1);
            color: white;
            display: flex;
            align-self: stretch;

            .kino-cv-template--header--name {
                display: flex;
                flex: 1;
                flex-direction: column;
                align-items: flex-end;
                padding: 50px 30px;
                @media screen and (max-width: 600px) {
                    padding: 25px 15px;
                }
                h1 {
                    text-transform: uppercase;
                    margin: 0;
                    font-weight: lighter;
                    color: $prim-color;
                    line-height: 1;
                    font-size: 3.2rem;
                    &:nth-of-type(2) {
                        color: white;
                    }
                    @media screen and (max-width: 600px) {
                        font-size: 1.8rem;
                    }
                }
                span {
                    text-transform: uppercase;
                    line-height: 2;
                    font-weight: lighter;
                    @media screen and (max-width: 600px) {
                        font-size: 0.6rem;
                    }
                }
            }
        }

        .kcvt-h-r {
            flex: 8;
            display: flex;
            justify-content: space-between;
            align-content: center;
            align-items: center;

            .kcvt-h-r-dp-holder {
                position: relative;
                &::before {
                    content: "";
                    position: absolute;
                    height: 20px;
                    width: 20px;
                    top: -20px;
                    background-color: #515151;
                    left: -30px;
                    border-radius: 50px;
                }

                &::after {
                    content: "";
                    position: absolute;
                    height: 140px;
                    width: 20px;
                    bottom: -20px;
                    background-color: #515151;
                    left: -30px;
                    border-radius: 50px;
                }

                background-color: #515151;
                border: 15px solid #515151;
                margin-left: -1px;
                -webkit-box-shadow: 4px 0px 5px 0px rgba(145, 145, 145, 1);
                -moz-box-shadow: 4px 0px 5px 0px rgba(145, 145, 145, 1);
                box-shadow: 4px 0px 5px 0px rgba(145, 145, 145, 1);
                padding: 0px 0px 0px 0px;
                border-top-right-radius: 100px;
                border-bottom-right-radius: 100px;
                .kcvt-h-r-dp {
                    height: 120px;
                    width: 120px;
                    background-color: white;
                    border-radius: 100px;
                }
            }

            .kcvt-h-r-o-holder {
                .kcvt-h-r-o-holder-head-border {
                    height: 5px;
                    width: 100px;
                    border-radius: 5px;
                    background-color: #515151;
                    margin-bottom: 15px;
                }
                .kcvt-h-r-o {
                    display: flex;
                    padding: 20px 50px;
                    flex-direction: column;
                    @media screen and (max-width: 600px) {
                        padding: 10px 25px;
                    }
                }
                .kcvt-h-r-o-holder-head {
                    color: #515151;
                    text-transform: uppercase;
                    font-weight: lighter;
                    @media screen and (max-width: 600px) {
                        font-size: 1.8rem;
                    }
                }
                .kcvt-h-r-o-holder-content {
                    color: #515151;
                    @media screen and (max-width: 600px) {
                        font-size: 0.8rem;
                    }
                }
            }
        }

        // .kino-cv-template--header--name {
        //     display: flex;
        //     flex-direction: column;
        //     padding: 40px;
        //     h1 {
        //         margin: 0;
        //         // &:nth-of-type(2) {
        //         //     text-align: center;
        //         // }
        //     }
        //     div {
        //         margin-top: 10px;
        //         span:first-of-type {
        //             color: #c87e2a;
        //         }
        //     }
        // }

        // .kino-cv-template--header--img {
        //     // margin-right: 110px;
        //     overflow: hidden;
        //     position: relative;
        //     padding: 30px 40px;
        //     height: 100%;
        // }

        .hexagon {
            overflow: hidden;
            visibility: hidden;
            -webkit-transform: rotate(120deg);
            -moz-transform: rotate(120deg);
            -ms-transform: rotate(120deg);
            -o-transform: rotate(120deg);
            transform: rotate(120deg);
            cursor: pointer;
        }
        .hexagon-in1 {
            overflow: hidden;
            width: 100%;
            height: 100%;
            -webkit-transform: rotate(-60deg);
            -moz-transform: rotate(-60deg);
            -ms-transform: rotate(-60deg);
            -o-transform: rotate(-60deg);
            transform: rotate(-60deg);
        }
        .hexagon-in2 {
            width: 100%;
            height: 100%;
            background-repeat: no-repeat;
            background-position: 50%;
            visibility: visible;
            transform: rotate(-60deg);
        }

        .hexagon2 {
            width: 150px;
            height: 300px;
            margin: -80px 0 0 20px;
        }
    }

    .kino-cv-template--header-sub {
        width: 100%;
        height: 50px;
        background-color: $prim-color;
        display: flex;
        justify-content: space-around;
        .kino-sh-s {
            display: flex;
            align-items: center;
            .kino-sh-s-i {
                background-color: #3e3d3d;
                border-radius: 50px;
                color: $prim-color;
                padding: 5px;
                display: flex;
                margin-right: 10px;
                align-self: center;
                @media screen and (max-width: 600px) {
                    margin-right: 5px;
                }
            }

            .kino-sh-s-c {
                color: #3e3d3d;
                @media screen and (max-width: 600px) {
                    font-size: 0.6rem;
                }
                a,
                a:hover {
                    color: #3e3d3d;
                    text-decoration: none;
                }
            }
        }
    }

    .content-wrapper {
        width: 100%;
        display: flex;
        align-content: center;
        justify-content: center;
        overflow: hidden;
        flex: 1;

        .left-sidebar {
            align-self: stretch;
            flex: 4;
            overflow: hidden;
            background-color: #515151;
            -webkit-box-shadow: 4px 0px 5px 0px rgba(145, 145, 145, 1);
            -moz-box-shadow: 4px 0px 5px 0px rgba(145, 145, 145, 1);
            box-shadow: 4px 0px 5px 0px rgba(145, 145, 145, 1);
            color: white;
            display: flex;
            flex-direction: column;
            .kino-cv-template--single-section {
                &:last-of-type {
                    margin-bottom: 20px;
                }
                .kino-cv-template--single-section--content {
                    margin-top: 10px;
                }
                .kino-cv-template--single-section--header {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-content: center;
                    margin-top: 15px;
                    .kino-cv-template--single-section--heading {
                        border-bottom: 5px solid $prim-color;
                        align-self: center;
                        border-radius: 3px;
                        color: $prim-color;
                        font-size: 2rem;
                        font-weight: lighter;
                        padding: 10px 0px;
                        @media screen and (max-width: 600px) {
                            font-size: 1.2rem;
                        }
                    }

                    .kino-cv-template--single-section--header-underline {
                        height: 0px;
                        border-top: 2px solid rgba(255, 255, 255, 0.5);
                        margin-top: -11.5px;
                        margin-bottom: 10px;
                        width: 80%;
                        align-self: center;
                    }
                }
            }

            .kino-cv-template--single-section--icon-list {
                display: flex;
                flex-direction: column;
                .kino-cv-template--single-section--icon-list-single {
                    display: flex;
                    justify-content: flex-start;
                    padding: 5px 50px;
                    overflow: hidden;
                    @media screen and (max-width: 600px) {
                        padding: 2.5px 25px;
                    }
                    a,
                    a:hover {
                        color: white;
                        text-decoration: none;
                    }
                    .kino-cv-template--single-section--icon-list-single-icon {
                        background-color: #555658;
                        border-radius: 50px;
                        color: white;
                        padding: 5px;
                        display: flex;
                        margin-right: 20px;
                        align-self: center;
                        @media screen and (max-width: 600px) {
                            margin-right: 10px;
                        }
                    }
                    .kino-cv-template--single-section--icon-list-single-content {
                        @media screen and (max-width: 600px) {
                            font-size: 0.6rem;
                        }
                    }
                }
            }

            .kino-cv-template--single-section--skill-list,
            .kino-cv-template--single-section--keyassets-list {
                display: flex;
                overflow: hidden;
                flex-direction: column;
                padding: 5px 50px;
                @media screen and (max-width: 600px) {
                    padding: 2.5px 25px;
                }
                .kino-cv-template--single-section--skill-list-single {
                    justify-content: space-between;
                    display: flex;
                    flex-direction: row;
                    width: 100%;
                    // padding: 5px 50px;

                    .kino-cv-template--single-section--skill-list-single-skill {
                        @media screen and (max-width: 600px) {
                            font-size: 0.6rem;
                        }
                    }
                    .kino-cv-template--single-section--skill-list-single-stars {
                        color: white;
                    }
                }
                &.kino-cv-template--single-section--keyassets-list {
                    flex-direction: row;
                    flex-wrap: wrap;
                    justify-content: center;
                    .kino-cv-template--single-section--skill-list-single {
                        width: auto;
                        padding: 5px 10px;
                    }
                }
            }

            .kino-cv-template--single-section--language-list {
                display: flex;
                justify-content: space-around;
                flex-flow: row wrap;
                padding: 0px 5px;
                @media screen and (max-width: 600px) {
                    padding: 0px 2.5px;
                }

                .kino-cv-template--single-section--language-list-single {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-content: center;
                    align-items: center;
                    margin: 5px 0px;
                    @media screen and (max-width: 600px) {
                        margin: 2.5px 0px;
                    }
                    .kino-cv-template--single-section--language-list-single-content {
                        text-overflow: ellipsis;
                        overflow: hidden;
                        white-space: nowrap;
                        font-size: 18px;
                        @media screen and (max-width: 600px) {
                            font-size: 9px;
                        }
                    }
                    .progress-circle-container {
                        display: flex;
                    }
                    .progress-circle {
                        position: relative;
                        display: inline-block;
                        width: 60px;
                        height: 60px;
                        border-radius: 50%;
                        background-color: rgba(85, 86, 88, 1);
                        border: 1px solid rgba(255, 255, 255, 0.2);
                        @media screen and (max-width: 600px) {
                            width: 30px;
                            height: 30px;
                        }
                    }
                    .progress-circle:after {
                        content: "";
                        display: inline-block;
                        width: 100%;
                        height: 100%;
                        border-radius: 50%;
                        -webkit-animation: colorload 2s;
                        animation: colorload 2s;
                    }
                    .progress-circle span {
                        font-size: 1rem;
                        color: #2e2e2e;
                        position: absolute;
                        background-color: white !important;
                        left: 50%;
                        top: 50%;
                        display: block;
                        width: 45px;
                        height: 45px;
                        line-height: 45px;
                        margin-left: -22.5px;
                        margin-top: -22.5px;
                        text-align: center;
                        border-radius: 50%;
                        font-size: 12px;
                        z-index: 1;
                        @media screen and (max-width: 600px) {
                            font-size: 0.5rem;
                            width: 22.5px;
                            height: 22.5px;
                            line-height: 22.5px;
                            font-size: 6px;
                            margin-left: -11px;
                            margin-top: -11px;
                        }
                    }
                    .progress-circle span:after {
                        content: "%";
                        font-weight: 600;
                        font-size: 12px;
                        color: #2e2e2e;
                        @media screen and (max-width: 600px) {
                            font-weight: 300;
                            font-size: 6px;
                        }
                    }
                    .progress-circle.progress-25:after {
                        background-image: linear-gradient(90deg, rgba(85, 86, 88, 1) 50%, transparent 50%, transparent),
                            linear-gradient(180deg, $prim-color 50%, rgba(85, 86, 88, 1) 50%, rgba(85, 86, 88, 1));
                    }
                    .progress-circle.progress-50:after {
                        background-image: linear-gradient(-90deg, $prim-color 50%, transparent 50%, transparent),
                            linear-gradient(270deg, $prim-color 50%, rgba(85, 86, 88, 1) 50%, rgba(85, 86, 88, 1));
                    }
                    .progress-circle.progress-75:after {
                        background-image: linear-gradient(0deg, $prim-color 50%, transparent 50%, transparent),
                            linear-gradient(270deg, $prim-color 50%, rgba(85, 86, 88, 1) 50%, rgba(85, 86, 88, 1));
                    }
                    .progress-circle.progress-100:after {
                        background-image: linear-gradient(90deg, $prim-color 50%, transparent 50%, transparent),
                            linear-gradient(270deg, $prim-color 50%, rgba(85, 86, 88, 1) 50%, rgba(85, 86, 88, 1));
                    }
                }
            }
        }

        .main-content {
            overflow: hidden;
            flex: 8;

            overflow: hidden;
            .main-content-wrapper {
                padding: 10px 30px 10px 50px;
            }

            .main-content--single-section {
                margin-top: 30px;
                &:last-of-type {
                    margin-bottom: 30px;
                }
                .main-content--single-section-heading {
                    font-size: 1.8rem;
                    display: flex;
                    align-content: center;
                    align-items: center;
                    span {
                        &:last-of-type {
                            padding-left: 100px;
                        }
                    }
                    color: #515151;
                    text-transform: uppercase;
                    @media screen and (max-width: 600px) {
                        font-size: 1.2rem;
                    }
                }
                .main-content--single-section-content {
                    border-left: 4px solid #515151;
                    padding-left: 20px;
                    &.job-list {
                        .main-content--single-section-content-single-job {
                            margin-top: 20px;

                            .main-content--single-section-content-single-job-company {
                                color: #515151;
                                font-weight: bold;
                                @media screen and (max-width: 600px) {
                                    font-size: 0.8rem;
                                }
                            }
                            .main-content--single-section-content-single-job-title {
                                color: #515151;
                                font-weight: bold;
                                font-size: 20px;
                                @media screen and (max-width: 600px) {
                                    font-size: 0.8rem;
                                }
                            }
                            .main-content--single-section-content-single-job-description{
                                @media screen and (max-width: 600px) {
                                    font-size: 0.8rem;
                                }
                            }
                        }
                    }

                    &.education-list {
                        .main-content--single-section-content-single-education {
                            margin-top: 10px;

                            .main-content--single-section-content-single-education-company {
                                color: #515151;
                                font-weight: bold;
                                @media screen and (max-width: 600px) {
                                    font-size: 0.8rem;
                                }
                            }
                            .main-content--single-section-content-single-education-title {
                                color: #515151;
                                font-weight: bold;
                                font-size: 20px;
                                @media screen and (max-width: 600px) {
                                    font-size: 0.8rem;
                                }
                            }
                            .main-content--single-section-content-single-education-description {
                                @media screen and (max-width: 600px) {
                                    font-size: 0.8rem;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
