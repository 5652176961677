@function reverse($list, $recursive: false) {
    $result: ();

    @for $i from length($list) * -1 through -1 {
        @if type-of(nth($list, abs($i))) == list and $recursive {
            $result: append($result, reverse(nth($list, abs($i)), $recursive));
        } @else {
            $result: append($result, nth($list, abs($i)));
        }
    }

    @return $result;
}

@mixin colorChanger($list) {
    @for $i from 1 through 12 {
        &:nth-of-type(#{$i}) {
            .section-heading-pal-bar {
                background-color: nth($list, if($i % length($list) == 0, 4, $i % length($list))) !important;
                .section-heading-pal-bar-handle-in {
                    border-color: nth($list, if($i % length($list) == 0, 4, $i % length($list))) !important;
                }
            }

            progress {
                &::-webkit-progress-value,
                &::-moz-progress-bar {
                    background: nth($list, max($i % length($list), 1)) !important;
                    border-radius: 20px;
                }
            }

            progress {
                border-radius: 10px;
                height: 8px;
                @media screen and (max-width: 600px) {
                    border-radius: 5px;
                    height: 4px;
                }
            }

            progress::-webkit-progress-bar {
                border: 0;
                height: 8px;
                border-radius: 20px;
                @media screen and (max-width: 600px) {
                    border-radius: 10px;
                    height: 4px;
                }
            }

            progress::-webkit-progress-value {
                background: nth($list, max($i % length($list), 1)) !important;
                border-radius: 20px;
                @media screen and (max-width: 600px) {
                    border-radius: 10px;
                }
            }
        }
    }
}

#Palette-template-cv {
    $prim-color: #2c5557;
    $color1: #ec542b;
    $color2: #39a2d4;
    $color3: #fcc20c;
    $color4: #1bb3b0;
    $colorList: $color1 $color2 $color3 $color4;
    // padding: 30px;
    width: 100%;
    min-width: 100%;
    color: #54545e;

    .margin-of-overlap {
        margin-top: 2rem;
        @media screen and (max-width: 600px) {
            margin-top: 1rem;
        }
    }

    .palette-main-timeline {
        padding: 0px 100px;
        @media screen and (max-width: 600px) {
            padding: 0px 0px;
        }

        .MuiTimelineItem-oppositeContent {
            &:first-of-type {
                margin-top: 50px;
                @media screen and (max-width: 600px) {
                    margin-top: 25px;
                }
            }
        }

        .MuiTimelineConnector-root {
            background-color: white;
            padding: 1px;
            border-left: 5px solid $prim-color;
            border-right: 5px solid $prim-color;
            border-bottom: 5px solid $prim-color;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
        }

        .MuiTimelineDot-root {
            margin-bottom: 5px;
        }

        .Palette-cv-template--single-contact {
            display: flex;
            flex-direction: row;
            margin: 10px 0px;
            @media screen and (max-width: 600px) {
                margin: 5px 0px;
            }

            &:first-of-type {
                margin-top: 30px;
                @media screen and (max-width: 600px) {
                    margin-top: 15px;
                }
            }

            .Palette-cv-template--single-section--icon-list-single-content {
                display: flex;
                align-items: center;
                flex: 1;
                @media screen and (max-width: 600px) {
                    font-size: 0.6rem;
                }
                .obcv-c-h,
                .obcv-c-v {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    align-self: flex-end;
                    &:not(.obcv-c-v) {
                        font-weight: bold;
                    }
                    &:not(.obcv-c-h) {
                        font-size: 13px;
                        @media screen and (max-width: 600px) {
                            font-size: 6.5px;
                        }
                    }
                }
            }
            .Palette-cv-template--single-contact--icon {
                margin: 0px 0px 0px 20px;
                @media screen and (max-width: 600px) {
                    margin: 0px 0px 0px 5px;
                }
            }
        }

        .pallete-social-acc-holder {
            display: flex;
            justify-content: space-around;
            flex-wrap: wrap;
            .Palette-cv-template--single-section--icon-list-single-icon {
                margin-right: 10px;
                @media screen and (max-width: 600px) {
                    margin-right: 5px;
                }
            }
            .Palette-cv-template--single-section--icon-list-single {
                margin: 10px 20px;
                @media screen and (max-width: 600px) {
                    font-size: 0.6rem;
                    margin: 5px 10px;
                }
                &:nth-of-type(1) {
                    .Palette-cv-template--single-section--icon-list-single-icon {
                        color: $color1;
                    }
                }

                &:nth-of-type(2) {
                    .Palette-cv-template--single-section--icon-list-single-icon {
                        color: $color2;
                    }
                }

                &:nth-of-type(3) {
                    .Palette-cv-template--single-section--icon-list-single-icon {
                        color: $color3;
                    }
                }

                &:nth-of-type(4) {
                    .Palette-cv-template--single-section--icon-list-single-icon {
                        color: $color4;
                    }
                }
            }
        }

        .MuiTimelineOppositeContent-root {
            .palette-single-item-main-timeline {
                @include colorChanger($colorList);
            }
        }

        .MuiTimelineContent-root {
            .palette-single-item-main-timeline {
                @include colorChanger(reverse($colorList));
            }
        }

        .palette-single-item-main-timeline {
            margin-top: 20px;
            @media screen and (max-width: 600px) {
                margin-top: 10px;
            }

            .section-heading-pal {
                display: flex;
                align-items: center;
                flex: 1;

                &.lefty {
                    h3 {
                        margin-right: 50px;
                        @media screen and (max-width: 600px) {
                            font-size: 1rem;
                            margin-right: 25px;
                        }
                    }
                }

                &.righty {
                    h3 {
                        margin-left: 50px;
                        @media screen and (max-width: 600px) {
                            font-size: 1rem;
                            margin-left: 25px;
                        }
                    }
                }

                .section-heading-pal-bar {
                    width: 100px;
                    height: 10px;
                    flex: 1;
                    background-color: orange;
                    position: relative;
                    margin-top: -8px;

                    .section-heading-pal-bar-handle {
                        position: absolute;
                        display: flex;
                        border-radius: 100px;
                        border: 2px solid white;

                        background: white;
                        .section-heading-pal-bar-handle-in {
                            border-radius: 100px;
                            border: 8px solid orange;
                            padding: 6px !important;
                        }
                    }

                    &.lefty {
                        margin-right: -20px;
                        border-top-left-radius: 5px;
                        border-bottom-left-radius: 5px;
                        .section-heading-pal-bar-handle {
                            border-left: none;
                            right: -18px;
                            top: -11px;
                        }
                    }
                    &.righty {
                        margin-left: -20px;
                        border-top-right-radius: 5px;
                        border-bottom-right-radius: 5px;
                        .section-heading-pal-bar-handle {
                            border-right: none;
                            left: -18px;
                            top: -11px;
                        }
                    }
                }
                display: flex;
            }

            .section-content-pal {
                p {
                    text-align: left;
                    @media screen and (max-width: 600px) {
                        font-size: 0.8rem;
                    }
                }
                .main-content--single-section-content {
                    &.job-list {
                        .single-education-entry-palette{
                            .s-ee-p-date{
                                @media screen and (max-width: 600px) {
                                    font-size: 0.8rem;
                                }
                            }
                            .s-ee-p-c-and-d{
                                @media screen and (max-width: 600px) {
                                    font-size: 0.8rem;
                                }
                            }
                        }
                    }
                }
            }

            .Palette-cv-template--single-section--keyassets-list {
                display: flex;
                overflow: hidden;
                flex-direction: column;

                &.Palette-cv-template--single-section--keyassets-list {
                    flex-direction: row;
                    flex-wrap: wrap;
                    justify-content: center;
                    .Palette-cv-template--single-section--skill-list-single {
                        width: auto;
                        padding: 5px 10px;
                        @media screen and (max-width: 600px) {
                            font-size: 0.8rem;
                            padding: 2.5px 5px;
                        }
                    }
                }
            }
        }

        .Palette-cv-template--single-section--language-list-single {
            display: flex;
            justify-content: space-around;
            padding: 5px 0px;
            @media screen and (max-width: 600px) {
                font-size: 0.8rem;
                padding: 2.5px 0px;
            }
        }

        .pal-alp-icon-h {
            border-radius: 152px;
            height: 25px;
            width: 25px;
            display: flex;
            align-content: center;
            justify-content: center;
            color: white;
            font-weight: bold;
            margin-right: 50px;
            @media screen and (max-width: 600px) {
                margin-right: 10px;
            }
            span {
                display: flex;
            }
        }

        .Palette-cv-template--single-section--skill-list-single {
            justify-content: space-around;
            display: flex;
            flex-direction: row;
            width: 100%;
            padding: 5px 0px;
            align-items: center;
            @media screen and (max-width: 600px) {
                font-size: 0.6rem;
                padding: 2.5px 0px;
            }

            .Palette-cv-template--single-section--skill-list-single-stars {
                min-width: 130px;
                svg {
                    margin: 0px 3px;
                    @media screen and (max-width: 600px) {
                        margin: 0px 1.5px;
                    }
                }
            }
        }

        .MuiTimelineDot-defaultGrey {
            background-color: transparent;
            box-shadow: none;
            border: 8px solid orange;
            padding: 6px !important;
            position: relative;
            @media screen and (max-width: 600px) {
                padding: 3px !important;
            }
        }
    }

    .header-top-margin {
        width: 100%;
        height: 100px;
        @media screen and (max-width: 600px) {
            height: 50px;
        }
    }

    .header-banner {
        height: 150px;
        width: 100%;
        display: flex;
        position: relative;
        @media screen and (max-width: 600px) {
            height: 75px;
        }

        .header-banner-img-h {
            position: absolute;
            width: 200px;
            height: 200px;
            background-color: $prim-color;
            border: 10px solid white;
            border-radius: 150px;
            left: 100px;
            top: -20px;
            -webkit-box-shadow: 0px 0px 5px 0px rgba(120, 120, 120, 0.6);
            -moz-box-shadow: 0px 0px 5px 0px rgba(120, 120, 120, 0.6);
            box-shadow: 0px 0px 5px 0px rgba(120, 120, 120, 0.6);
            img {
                width: 100%;
            }
            overflow: hidden;
        }

        .header-banner-l {
            display: flex;
            flex-direction: column;
            width: 200px;
            @media screen and (max-width: 600px) {
                width: 100px;
            }
            .header-banner-l-t {
                flex: 1;
                background-color: #ec542b;
            }

            .header-banner-l-b {
                flex: 1;
                background-color: #f7d8cd;
            }
        }

        .header-banner-r {
            flex: 1;
            display: flex;
            padding: 0px 50px 0px 120px;
            background-color: $prim-color;
            color: white;
            align-items: center;
            @media screen and (max-width: 600px) {
                padding: 0px 25px 0px 20px;
            }

            .header-banner-r-name-h {
                flex: 1;
                h1 {
                    @media screen and (max-width: 600px) {
                        font-size: 1.4rem;
                    }
                }
            }
            .header-banner-pos-h {
                h3 {
                    @media screen and (max-width: 600px) {
                        font-size: 1rem;
                    }
                }
            }
        }
    }

    .Palette-cv-template--header {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        overflow: hidden;
        padding: 20px 0px;
        @media screen and (max-width: 600px) {
            padding: 10px 0px;
        }

        justify-content: space-between;

        .Palette-cv-template--header--name {
            display: flex;
            flex-direction: column;
            padding: 0px 0px;
            h1 {
                margin: 0;
                // &:nth-of-type(2) {
                //     text-align: center;
                // }
                font-size: 4rem;
                text-transform: uppercase;
                @media screen and (max-width: 600px) {
                    font-size: 2rem;
                }
            }
            div {
                margin-top: 10px;
                @media screen and (max-width: 600px) {
                    font-size: 1rem;
                }
                span:first-of-type {
                    color: #c87e2a;
                }
            }
        }

        // .Palette-cv-template--header--img {
        //     // margin-right: 110px;
        //     overflow: hidden;
        //     position: relative;
        //     padding: 30px 40px;
        //     height: 100%;
        // }

        // .hexagon {
        //     overflow: hidden;
        //     visibility: hidden;
        //     -webkit-transform: rotate(120deg);
        //     -moz-transform: rotate(120deg);
        //     -ms-transform: rotate(120deg);
        //     -o-transform: rotate(120deg);
        //     transform: rotate(120deg);
        //     cursor: pointer;
        // }
        // .hexagon-in1 {
        //     overflow: hidden;
        //     width: 100%;
        //     height: 100%;
        //     -webkit-transform: rotate(-60deg);
        //     -moz-transform: rotate(-60deg);
        //     -ms-transform: rotate(-60deg);
        //     -o-transform: rotate(-60deg);
        //     transform: rotate(-60deg);
        // }
        // .hexagon-in2 {
        //     width: 100%;
        //     height: 100%;
        //     background-repeat: no-repeat;
        //     background-position: 50%;
        //     visibility: visible;
        //     transform: rotate(-60deg);
        // }

        // .hexagon2 {
        //     width: 150px;
        //     height: 300px;
        //     margin: -80px 0 0 20px;
        // }
    }

    .obcv-profession {
        background-color: $prim-color;
        color: rgba(255, 255, 255, 0.88);
        padding: 2px 10px 2px 50px;
        font-size: 1.3rem;
        @media screen and (max-width: 600px) {
            padding: 1px 5px 1px 25px;
            font-size: 0.6rem;
        }
    }

    .obcv-hero {
        display: flex;
        margin-bottom: 50px;
        @media screen and (max-width: 600px) {
            margin-bottom: 25px;
        }
        .obcv-hero-img {
            background-color: #54545e;
        }
        .obcv-hero-content {
            padding: 50px 0px 25px 0px;
            text-transform: uppercase;
            border-bottom: 3px solid rgba(0, 0, 0, 0.5);
            @media screen and (max-width: 600px) {
                padding: 25px 0px 12.5px 0px;
            }
            .obcv-hero-content-title {
                font-weight: normal;
                margin-bottom: 5px;
                @media screen and (max-width: 600px) {
                    margin-bottom: 2.5px;
                }
            }
            .obcv-hero-content-title-border {
                display: block;
                height: 3px;
                background: $prim-color;
                width: 100px;
                margin: 0px 0px 10px 5px;
                @media screen and (max-width: 600px) {
                    height: 1.5px;
                    margin: 0px 0px 5px 2.5px;
                }
            }
        }
    }

    .obcv-t-l {
        .obcv-t-l-c {
            text-transform: uppercase;
            font-size: 1.1rem;
            @media screen and (max-width: 600px) {
                font-size: 0.8rem;
            }
        }
    }
    .obcv-t-r {
        .obcv-t-r-t {
            text-transform: uppercase;
            font-size: 1.1rem;
            font-weight: normal;
            @media screen and (max-width: 600px) {
                font-size: 0.8rem;
            }
        }
        .obcv-t-r-c {
            font-size: 0.9rem;
            @media screen and (max-width: 600px) {
                font-size: 0.8rem;
            }
        }
    }

    .obcv-t-content-wrapper-r {
        flex: 2;
    }

    .obcv-t {
        .MuiTimelineDot-defaultGrey {
            background-color: #54545e;
            padding: 6px;
            margin-bottom: 0px;
            margin-top: 0px;
            @media screen and (max-width: 600px) {
                padding: 3px;
            }
        }
        .MuiTimelineConnector-root {
            background-color: #54545e;
            width: 3px;
            @media screen and (max-width: 600px) {
                width: 1.5px;
            }
        }
    }

    .content-wrapper {
        width: 100%;
        display: flex;
        align-content: center;
        justify-content: center;
        overflow: hidden;

        .Palette-cv-template--single-section {
            &:not(:last-of-type) {
                margin-bottom: 20px;
                @media screen and (max-width: 600px) {
                    margin-bottom: 10px;
                }
            }
            .Palette-cv-template--single-section--header {
                display: flex;
                flex-direction: row;
                align-content: center;
                margin-bottom: 10px;
                border-bottom: 3px solid rgba(0, 0, 0, 0.5);
                justify-content: space-between;
                @media screen and (max-width: 600px) {
                    margin-bottom: 5px;
                }
                .Palette-cv-template--single-section--heading {
                    align-self: center;
                    border-radius: 3px;
                    font-size: 1.8rem;
                    text-transform: uppercase;
                    margin: 3px 0px;
                    @media screen and (max-width: 600px) {
                        font-size: 0.9rem;
                        margin: 1.5px 0px;
                    }
                }

                .Palette-cv-template--single-section--header-icon {
                    display: flex;
                    align-content: center;
                    justify-content: center;
                    align-items: center;
                }
            }
        }

        .left-sidebar {
            flex: 4;
            overflow: hidden;

            .Palette-cv-template--single-section--icon-list {
                display: flex;
                flex-direction: column;
                .Palette-cv-template--single-section--icon-list-single {
                    display: flex;
                    justify-content: flex-start;
                    padding: 5px 0px;
                    overflow: hidden;
                    @media screen and (max-width: 600px) {
                        font-size: 0.6rem;
                        padding: 2.5px 0px;
                    }
                    .Palette-cv-template--single-section--icon-list-single-icon {
                        background-color: #54545e;
                        border-radius: 5px;
                        color: white;
                        padding: 5px;
                        display: flex;
                        margin-right: 20px;
                        align-self: center;
                        opacity: 0.95;
                        @media screen and (max-width: 600px) {
                            padding: 2.5px;
                        }
                    }
                }
            }

            .Palette-cv-template--single-section--skill-list,
            .Palette-cv-template--single-section--language-list {
                display: flex;
                justify-content: space-around;
                flex-flow: row wrap;
                // padding: 0px 5px;

                .Palette-cv-template--single-section--language-list-single {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-content: center;
                    align-items: center;
                    margin: 5px 0px;
                    @media screen and (max-width: 600px) {
                        font-size: 0.6rem;
                        margin: 2.5px 0px;
                    }
                    .Palette-cv-template--single-section--language-list-single-content {
                        text-overflow: ellipsis;
                        overflow: hidden;
                        white-space: nowrap;
                    }
                    .progress-circle-container {
                        display: flex;
                    }
                    .progress-circle {
                        position: relative;
                        display: inline-block;
                        width: 60px;
                        height: 60px;
                        border-radius: 50%;
                        background-color: rgba(85, 86, 88, 1);
                    }
                    .progress-circle:after {
                        content: "";
                        display: inline-block;
                        width: 100%;
                        height: 100%;
                        border-radius: 50%;
                        -webkit-animation: colorload 2s;
                        animation: colorload 2s;
                    }
                    .progress-circle span {
                        font-size: 1rem;
                        color: white;
                        position: absolute;
                        background-color: rgba(85, 86, 88, 1) !important;
                        left: 50%;
                        top: 50%;
                        display: block;
                        width: 45px;
                        height: 45px;
                        line-height: 45px;
                        margin-left: -22.5px;
                        margin-top: -22.5px;
                        text-align: center;
                        border-radius: 50%;
                        font-size: 12px;
                        z-index: 1;
                    }
                    .progress-circle span:after {
                        content: "%";
                        font-weight: 600;
                        font-size: 12px;
                        color: white;
                    }
                    .progress-circle.progress-25:after {
                        background-image: linear-gradient(90deg, rgba(85, 86, 88, 1) 50%, transparent 50%, transparent),
                            linear-gradient(180deg, $prim-color 50%, rgba(85, 86, 88, 1) 50%, rgba(85, 86, 88, 1));
                    }
                    .progress-circle.progress-50:after {
                        background-image: linear-gradient(-90deg, $prim-color 50%, transparent 50%, transparent),
                            linear-gradient(270deg, $prim-color 50%, rgba(85, 86, 88, 1) 50%, rgba(85, 86, 88, 1));
                    }
                    .progress-circle.progress-75:after {
                        background-image: linear-gradient(0deg, $prim-color 50%, transparent 50%, transparent),
                            linear-gradient(270deg, $prim-color 50%, rgba(85, 86, 88, 1) 50%, rgba(85, 86, 88, 1));
                    }
                    .progress-circle.progress-100:after {
                        background-image: linear-gradient(90deg, $prim-color 50%, transparent 50%, transparent),
                            linear-gradient(270deg, $prim-color 50%, rgba(85, 86, 88, 1) 50%, rgba(85, 86, 88, 1));
                    }
                }
            }
        }

        .main-content {
            overflow: hidden;
            flex: 9;
            padding: 0px 0px 10px 30px;
            @media screen and (max-width: 600px) {
                padding: 0px 0px 5px 15px;
            }

            .main-content--single-section {
                &:not(:last-of-type) {
                    margin-bottom: 30px;
                }
                .main-content--single-section-heading {
                    color: $prim-color;
                    border-bottom: 5px solid $prim-color;
                    padding-bottom: 15px;
                }
                .main-content--single-section-content {
                    &.job-list {
                        .single-education-entry-palette {
                            margin-top: 20px;

                            .main-content--single-section-content-single-job-company {
                                color: $prim-color;
                                font-weight: bold;
                            }
                            .main-content--single-section-content-single-job-title {
                                color: $prim-color;
                                font-weight: bold;
                                font-size: 20px;
                                @media screen and (max-width: 600px) {
                                    font-size: 10px;
                                }
                            }
                        }
                    }

                    &.education-list {
                        .main-content--single-section-content-single-education {
                            margin-top: 10px;

                            .main-content--single-section-content-single-education-company {
                                color: $prim-color;
                                font-weight: bold;
                            }
                            .main-content--single-section-content-single-education-title {
                                color: $prim-color;
                                font-weight: bold;
                                font-size: 20px;
                                @media screen and (max-width: 600px) {
                                    font-size: 10px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
