.jumbotron.error-hero {
    background: transparent;
    background-repeat: no-repeat;
}

.error-message-wrapper {
    padding: 70px;
    display: flex;
    flex-direction: column;
    // align-items: center;
    align-content: center;
    justify-content: center;
    height: 100%;
}
